import React from 'react';
import Dropzone from 'react-dropzone';
import { convertToKB } from '../utils';

const FileDropzone = ({ accept, maxFiles, file, handleDropAccepted, handleDropRejected }) => {
  return (
    <Dropzone
      accept={accept}
      maxFiles={maxFiles}
      onDropAccepted={handleDropAccepted}
      onDropRejected={handleDropRejected}>
      {({ getRootProps, getInputProps }) => (
        <section className="flex flex-col space-y-4">
          <div
            {...getRootProps({
              className:
                'dropzone h-64 flex justify-center items-center bg-gray-100 border-2 border-gray-400 border-dashed rounded-md hover:cursor-pointer hover:bg-gray-200'
            })}>
            <input {...getInputProps()} />
            <p className="text-gray-500">
              Click to browse or
              <br />
              drag and drop your files
            </p>
          </div>
          {file && (
            <div>
              <p>
                {file.path} - {convertToKB(file.size)} KB
              </p>
            </div>
          )}
        </section>
      )}
    </Dropzone>
  );
};

export default FileDropzone;
