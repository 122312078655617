import React, { Fragment } from 'react';
import { Outlet } from 'react-router';
import NavBar from './NavBar';

const Layout = () => {
  return (
    <Fragment>
      <NavBar />
      <div className="container mx-auto mt-14">
        <Outlet />
      </div>
    </Fragment>
  );
};

export default Layout;
