import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal } from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { swalConfirmation, swalSuccess } from '../../utils';
import { toast } from 'react-toastify';
import Toast from '../Toast';
import { useDispatch, useSelector } from 'react-redux';
import { getUserCourses, updateCourses, clearCourseState } from '../../store/courses.store';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 0.4,
  bgcolor: 'white',
  boxShadow: 24,
  p: 4
};

const UpdateCourseModal = ({ open, handleClose, row }) => {
  const dispatch = useDispatch();
  const { isSuccess, isError, errorMessage } = useSelector((state) => state.courses);
  const { user } = useSelector((state) => state.user);
  const [disableButton, setDisableButton] = useState(false);
  const [form, setForm] = useState({
    name: row.name,
    startDate: row.startDate,
    endDate: row.endDate
  });

  /**
   * Close modal only when user clicks on the 'x' button.
   *
   * Resets local states before closing.
   *
   * @param {*} _ - unused parameter
   * @param {*} reason - reason for closing modal
   */
  const handleModalClose = async (_, reason) => {
    if (reason !== 'backdropClick') {
      const { isConfirmed } = await swalConfirmation(
        'Closing this modal will discard all changes. Are you sure?'
      );
      if (isConfirmed) {
        resetStates();
        handleClose();
      }
    }
  };

  /**
   * Handle form input change.
   *
   * Set local state with new value.
   */
  const handleFormInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  /**
   * Handle course update.
   *
   * 1. Validate form inputs.
   * 2. Show confirmation alert.
   * 3. Append data to form data.
   * 4. Send form data to server.
   */
  const handleUpdate = async () => {
    if (!validateForm()) return;
    const { isConfirmed } = await swalConfirmation('Are you sure you want to update this course?');
    if (!isConfirmed) return;
    setDisableButton(true);
    dispatch(clearCourseState());
    dispatch(updateCourses({ courseId: row.id, data: form }));
  };

  /**
   * Validate form data.
   *
   * @returns {boolean} true if form is valid, false otherwise
   */
  const validateForm = () => {
    if (!form.name || !form.startDate || !form.endDate) {
      toast.error('Please fill in all fields.');
      return false;
    }
    return true;
  };

  /**
   * Handle isSuccess or isError state change.
   *
   * isSuccess:
   *  1. Show success alert.
   *  2. Dispatch action to clear course state.
   *  3. Dispatch action to get courses.
   *  4. Close modal.
   *
   * isError:
   *  1. Show error toast.
   *  2. Dispatch action to clear course state.
   *  3. Enable button.
   */
  useEffect(() => {
    if (!open) return;

    if (isSuccess) {
      swalSuccess('Course updated successfully').then(() => {
        resetStates();
        dispatch(clearCourseState());
        dispatch(getUserCourses({ accountId: user.id }));
        handleClose();
      });
    } else if (isError) {
      toast.error(errorMessage ?? 'Error updating course');
      dispatch(clearCourseState());
      setDisableButton(false);
      setForm('');
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (!open) return;
    if (form.name === undefined && form.startDate === undefined && form.endDate === undefined) {
      setForm(row);
    }
  });

  /**
   * Reset local states to default.
   */
  const resetStates = () => {
    setDisableButton(false);
    setForm('');
  };

  return (
    <Fragment>
      <Modal open={open} disableEscapeKeyDown>
        <Box sx={style}>
          <CloseIcon
            className="absolute right-0 top-0 m-2 cursor-pointer transition ease-in-out delay-130 hover:scale-110 duration-300"
            onClick={handleModalClose}
          />
          <div className="flex flex-col space-y-6 text-center">
            <div>
              <h1>Update Course Details</h1>
            </div>
            <div className="grid grid-cols-3 gap-4 items-center">
              <div>Course Name</div>
              <div className="col-span-2">
                <input
                  type="text"
                  placeholder="search course..."
                  className="input input-bordered w-full"
                  name="name"
                  defaultValue={row.name}
                  onChange={handleFormInputChange}
                />
              </div>
              <div>Start Date</div>
              <div className="col-span-2">
                <input
                  type="datetime-local"
                  className="input input-bordered w-full"
                  name="startDate"
                  defaultValue={row.startDate}
                  onChange={handleFormInputChange}
                />
              </div>
              <div>End Date</div>
              <div className="col-span-2">
                <input
                  type="datetime-local"
                  className="input input-bordered w-full"
                  name="endDate"
                  defaultValue={row.endDate}
                  onChange={handleFormInputChange}
                />
              </div>
            </div>

            <Button
              className="btn btn-primary"
              variant="contained"
              disabled={disableButton}
              onClick={handleUpdate}>
              Update Course
            </Button>
          </div>
        </Box>
      </Modal>
      <Toast />
    </Fragment>
  );
};

export default UpdateCourseModal;
