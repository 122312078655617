import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router';
import { logout } from '../store/auth.store';
import { getUser } from '../store/user.store';
import { setAuthToken } from '../utils/auth-token.utils';

const AuthRoute = ({ allowedRole }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { user, isSuccess } = useSelector((state) => state.user);

  if (localStorage.getItem('token')) {
    setAuthToken(localStorage.getItem('token'));
  }

  useEffect(() => {
    dispatch(getUser());
  }, []);

  if (isAuthenticated) {
    if (!allowedRole) return <Outlet />;

    if (isSuccess && !user.roles.includes(allowedRole)) {
      return <Navigate to={{ pathname: '/unauthorized', state: { from: location } }} />;
    }
    return <Outlet />;
  } else {
    dispatch(logout());
    return <Navigate to="/login" replace />;
  }
};

export default AuthRoute;
