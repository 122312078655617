import React from 'react';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { motion } from 'framer-motion';
import DeleteIcon from '@mui/icons-material/Delete';

const FreeResponseQuestion = ({ data, handleFormChange, handleDelete }) => {
  const { question, minLength } = data;

  return (
    <motion.div
      animate={{
        scale: [1, 1.03, 1],
        transition: { duration: 0.3 }
      }}>
      <Box
        className="p-4 mt-4 rounded-lg shadow-sm bg-gray-50"
        sx={{ width: '100%', height: 'auto' }}>
        <div className="flex flex-col justify-center space-y-4">
          <div className="form-control">
            <label className="label">
              <span className="label-text font-bold">Question</span>
            </label>
            <input
              name="question"
              className="input input-bordered w-full"
              placeholder="Type your question here"
              value={question}
              onChange={handleFormChange}
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text font-bold">Min Length</span>
            </label>
            <label className="input-group">
              <input
                type="number"
                min={1}
                name="minLength"
                className="input input-bordered w-20"
                placeholder="Type here"
                value={minLength}
                onChange={handleFormChange}
              />
              <span className="bg-gray-150">Words</span>
            </label>
          </div>
        </div>
        <div className="flex justify-end">
          <Button className="btn btn-error mt-2" endIcon={<DeleteIcon />} onClick={handleDelete}>
            Delete
          </Button>
        </div>
      </Box>
    </motion.div>
  );
};

export default FreeResponseQuestion;
