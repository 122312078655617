import http from '../http';
import { setAuthToken } from '../../utils/auth-token.utils';

if (localStorage.getItem('token')) {
  setAuthToken(localStorage.getItem('token'));
}
export const getMe = () => http.get('/users/me');

export const importUsers = (data) => http.post('/users/import', data);

export const getInstructors = () => http.get('/users/instructors');

export const createInstructors = (data) => http.post('/users/instructors', data);
