import React, { useState, useEffect } from 'react';
import ImportClasslist from '../../../components/Class/ImportClasslist';
import CreateClassDetail from '../../../components/Class/CreateClassDetail';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import NavSteps from '../../../components/NavSteps';
import { swalConfirmation, swalSuccess } from '../../../utils';
import { createClass, clearClassState } from '../../../store/class.store';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Toast from '../../../components/Toast';
import { classService } from '../../../api/services';

const CreateClass = () => {
  const dispatch = useDispatch();
  const [students, setStudents] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [classDetail, setClassDetail] = useState({ instructors: [], name: '' });
  const [disableCreateBtn, setDisableCreateButton] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const { courseId } = params;
  const steps = [
    {
      title: 'Class Details',
      icon: <HomeIcon />
    },
    {
      title: 'Import Classlist',
      icon: <PersonIcon />
    }
  ];

  const handleCreate = async () => {
    if (classDetail && students.length) {
      const { isConfirmed } = await swalConfirmation('Are you sure you want to create this class?');
      if (!isConfirmed) return;
      setDisableCreateButton(true);
      dispatch(clearClassState());
      const createClassDetail = {
        courseId,
        data: {
          ...classDetail,
          courseId: parseInt(courseId),
          studentsList: students
        }
      };

      try {
        await classService.createClass(createClassDetail);
        await swalSuccess('Class created successfully');
        navigate('/instructor/courses/' + courseId + '/classes', { replace: true });
      } catch (error) {
        console.log({ error });
        toast.error('Error creating class');
        toast.error(
          'Please make sure students does not already belong to another class in this course.',
          {
            autoClose: 5000
          }
        );
        toast.error(error.response?.data?.responseException?.exceptionMessage, {
          autoClose: 10000
        });
      }
      setDisableCreateButton(false);
    }
  };

  /**
   * Decide which component to show based on current step
   *
   * Index 0: Create Class component
   * Index 1: Import Classlist component
   *
   * @returns {JSX.Element} - The component to render
   */
  const showComponent = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className="flex flex-col">
            <CreateClassDetail
              setCurrentStep={setCurrentStep}
              setClassDetail={setClassDetail}
              classDetail={classDetail}
            />
            <Toast />
            <div className="flex justify-end mt-3"></div>
          </div>
        );
      case 1:
        return (
          <div className="flex flex-col">
            <ImportClasslist
              students={students}
              setStudents={setStudents}
              handleCreateClass={handleCreate}
              disableCreateBtn={disableCreateBtn}
            />
          </div>
        );
      default:
        break;
    }
  };
  return (
    <div>
      <NavSteps steps={steps} currentStep={currentStep} setCurrentStep={setCurrentStep} />
      <div className="mx-auto w-1/2 mt-5">{showComponent()}</div>
    </div>
  );
};

export default CreateClass;
