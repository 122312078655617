import { Button, LinearProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { reviewService } from '../../../api/services';
import Breadcrumb from '../../../components/Breadcrumb';
import { DocumentViewer } from 'react-documents';
import FreeResponseReview from '../../../components/Review/FreeResponseReview';
import ScaleResponseReview from '../../../components/Review/ScaleResponseReview';
import Toast from '../../../components/Toast';
import { QuestionType } from '../../../constants';
import { getWordCount, swalConfirmation, swalSuccess } from '../../../utils';
import { useDispatch } from 'react-redux';
import { popBreadcrumb } from '../../../store/breadcrumb.store';

const MyReview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { courseId, assignmentId, submissionId, reviewId } = params;
  const data = {
    courseId,
    assignmentId: assignmentId,
    submissionId: submissionId,
    reviewId: reviewId
  };

  const feedback = [
    {
      qnTypeId: QuestionType.SCALE_RESPONSE,
      question: 'What is the validity of the content?',
      scaleSize: 5,
      minLabel: 'Terrible',
      maxLabel: 'Excellent',
      scaleAnswer: 3
    },
    {
      qnTypeId: QuestionType.SCALE_RESPONSE,
      question: 'How is the structure of the report?',
      scaleSize: 5,
      minLabel: 'Terrible',
      maxLabel: 'Excellent',
      scaleAnswer: 3
    },
    {
      qnTypeId: QuestionType.FREE_RESPONSE,
      question: 'What is good about this assignment?',
      minLength: 10,
      answer: ''
    },
    {
      qnTypeId: QuestionType.FREE_RESPONSE,
      question: 'What can be improved in this assignment?',
      minLength: 10,
      answer: ''
    }
  ];

  const initialReviewState = {
    assignmentName: '',
    fileUrl: '',
    question: [],
    studentName: ''
  };
  const [inputFields, setInputFields] = useState(feedback);
  const [review, setReview] = useState(initialReviewState);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const [showProgress, setShowProgress] = useState(true);

  const handleInputFieldChange = (e, index) => {
    const { name, value } = e.target;
    const newInputFields = [...inputFields];
    newInputFields[index][name] = value;
    setInputFields(newInputFields);
  };

  const handleSubmit = async () => {
    const freeResponseAns = inputFields.filter(
      (input) => input.qnTypeId === QuestionType.FREE_RESPONSE
    );

    for (const { answer, minLength } of freeResponseAns) {
      if (!isValidInput(answer, minLength)) {
        toast.error('Please fulfill the minimum word count requirement');
        return;
      }
    }
    try {
      const { isConfirmed } = await swalConfirmation(
        'Are you sure you want to submit this review?'
      );
      if (!isConfirmed) {
        return;
      }
      setDisableSubmitBtn(true);
      await reviewService.submitReview(data, inputFields);
      await swalSuccess('Your review have been submitted.');
      dispatch(popBreadcrumb());
      navigate(-1);
      setDisableSubmitBtn(false);
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong...unable to submit your review');
      setDisableSubmitBtn(false);
    }
  };

  const handleSave = async () => {
    try {
      setDisableSaveBtn(true);
      await reviewService.saveReview(data, getCleanData());
      toast.success('Your feedback have been saved');
      setDisableSaveBtn(false);
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong... review not saved');
    }
  };

  const isValidInput = (str, minLength) => getWordCount(str) >= minLength;

  const getPeerFeedback = async () => {
    try {
      const res = await reviewService.getPeerReview(data);
      const reviewResult = res.data?.result[0];
      if (reviewResult) {
        setReview(reviewResult);
        setInputFields(reviewResult.question);
      }
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong... unable to retrieve review');
    }
  };

  useEffect(() => {
    getPeerFeedback();
  }, []);

  const getCleanData = () => {
    return inputFields.map((question) => ({
      questionId: question.questionId,
      answer: question.answer,
      scaleAnswer: question.scaleAnswer ?? Math.ceil(question.scaleSize / 2)
    }));
  };

  return (
    <div className="pb-10">
      <h1>{review?.assignmentName}</h1>
      <Breadcrumb />
      <div className="flex justify-between my-4 space-x-10">
        <div className="w-full">
          {showProgress && <LinearProgress />}
          <DocumentViewer
            className="w-full drop-shadow-md"
            viewer="office"
            url={review?.fileUrl}
            style={{ height: '80vh' }}
            loaded={() => setShowProgress(false)}
          />
        </div>
        <div
          className="w-1/2 bg-gray-100 p-4 overflow-y-auto scrollbar"
          style={{ maxHeight: '80vh' }}>
          {review?.question.map((question, index) =>
            question.qnTypeId === QuestionType.FREE_RESPONSE ? (
              <FreeResponseReview
                key={index}
                data={question}
                handleFormChange={(e) => handleInputFieldChange(e, index)}
              />
            ) : (
              <ScaleResponseReview
                key={index}
                data={question}
                handleFormChange={(e) => handleInputFieldChange(e, index)}
              />
            )
          )}
        </div>
      </div>
      <div className="flex space-x-4 justify-end">
        <Button className="btn btn-primary" disabled={disableSaveBtn} onClick={handleSave}>
          Save
        </Button>
        <Button className="btn btn-success" disabled={disableSubmitBtn} onClick={handleSubmit}>
          Submit
        </Button>
      </div>
      {/* </div> */}
      <Toast />
    </div>
  );
};

export default MyReview;
