import { Box, Slider } from '@mui/material';
import React from 'react';

const ViewScaleResponseReview = ({ data }) => {
  const { question, scaleAnswer, minLabel, maxLabel, scaleSize } = data;
  return (
    <Box
      className="h-auto p-4 mt-4 bg-gray-50 text-center flex flex-col justify-center rounded-md drop-shadow-sm"
      sx={{ width: '100%', height: 'auto' }}>
      <div className="flex flex-col my-4">
        <span className="font-bold">{question}</span>
        <div className="flex justify-center my-12 space-x-4">
          <span>{minLabel}</span>
          <Slider
            name="answer"
            className="w-1/2"
            value={scaleAnswer}
            step={1}
            min={1}
            max={scaleSize}
            valueLabelDisplay="on"
            marks
            disabled
          />
          <span>{maxLabel}</span>
        </div>
      </div>
    </Box>
  );
};

export default ViewScaleResponseReview;
