import { Button, Checkbox, Chip, FormControlLabel, FormGroup, Modal } from '@mui/material';
import { Box } from '@mui/system';
import React, { Fragment, useEffect } from 'react';
import Toast from '../Toast';
import CloseIcon from '@mui/icons-material/Close';
import { swalConfirmation } from '../../utils';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { QuestionType } from '../../constants';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 0.5,
  bgcolor: 'white',
  boxShadow: 24,
  p: 4
};

const ImportRubricModal = ({ open, handleClose, handleImportQuestions, questions }) => {
  const [checkboxState, setCheckboxState] = useState([]);

  /**
   * Close modal only when user clicks on the 'x' button.
   *
   * Resets local states before closing.
   *
   * @param {*} _ - unused parameter
   * @param {*} reason - reason for closing modal
   */
  const handleModalClose = async (_, reason) => {
    if (reason !== 'backdropClick') {
      const { isConfirmed } = await swalConfirmation(
        'Closing this modal will discard all changes. Are you sure?'
      );
      if (!isConfirmed) return;
      handleClose();
    }
  };

  const handleSelectAll = (e) => {
    const { checked } = e.target;
    if (checked) {
      setCheckboxState(questions.map(() => true));
    } else {
      setCheckboxState(questions.map(() => false));
    }
  };

  const handleChecked = (index) => {
    const newCheckboxState = [...checkboxState];
    newCheckboxState[index] = !newCheckboxState[index];
    setCheckboxState(newCheckboxState);
  };

  const handleImport = async () => {
    const selectedQuestions = questions.filter((_, index) => checkboxState[index]);
    if (selectedQuestions.length === 0) {
      toast.error('Please select at least one question to import');
      return;
    }
    const { isConfirmed } = await swalConfirmation(
      'Are you sure you want to import these questions?'
    );
    if (!isConfirmed) return;
    handleImportQuestions(selectedQuestions);
    handleClose();
  };

  const displayChip = (questionType) => {
    switch (questionType) {
      case QuestionType.FREE_RESPONSE:
        return <Chip className="mt-1" color="primary" label="Free Response" variant="outlined" />;
      case QuestionType.SCALE_RESPONSE:
        return (
          <Chip className="mt-1" color="secondary" label="Scale Response" variant="outlined" />
        );
      default:
        return <Chip className="mt-1" color="primary" label="Free Response" variant="outlined" />;
    }
  };

  useEffect(() => {
    const initialCheckboxState = questions.map(() => false);
    console.log({ initialCheckboxState, questions });
    setCheckboxState(initialCheckboxState);
  }, [questions]);

  return (
    <Fragment>
      <Modal open={open} disableEscapeKeyDown>
        <Box sx={style}>
          <CloseIcon
            className="absolute right-0 top-0 m-2 cursor-pointer transition ease-in-out delay-130 hover:scale-110 duration-300"
            onClick={handleModalClose}
          />
          <div className="flex flex-col space-y-6 text-center">
            <div>
              <h1>Import Rubric Questions</h1>
              <p className="text-gray-500">
                Select the questions that you want to import from this rubric.
              </p>
            </div>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox onChange={handleSelectAll} />}
                label="Select All"
              />
              <hr />
              {questions.map((question, index) => (
                <div key={index} className="flex ">
                  <FormControlLabel
                    key={index}
                    control={
                      checkboxState.length && (
                        <Checkbox
                          checked={checkboxState[index]}
                          onChange={() => handleChecked(index)}
                        />
                      )
                    }
                    label={question.question}
                  />
                  {displayChip(question.qnTypeId)}
                </div>
              ))}
            </FormGroup>
            <Button className="btn btn-primary" variant="contained" onClick={handleImport}>
              Import Questions
            </Button>
          </div>
        </Box>
      </Modal>
      <Toast />
    </Fragment>
  );
};

export default ImportRubricModal;
