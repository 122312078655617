import http from '../http';

export const importSubmissions = (data) => http.post('/submissions/import', data);

export const deleteImportedSubmissions = (data) => http.post('/submissions/import/delete', data);

export const createSubmissions = ({ courseId, classId, assignmentId, submissions }) =>
  http.post(
    `/courses/${courseId}/classes/${classId}/assignments/${assignmentId}/submissions`,
    submissions
  );

export const manualCreateSubmission = ({ courseId, classId, assignmentId, formData }) =>
  http.post(
    `/courses/${courseId}/classes/${classId}/assignments/${assignmentId}/submissions/manual`,
    formData
  );

export const getSubmissions = ({ courseId, classId, assignmentId }) =>
  http.get(`/courses/${courseId}/classes/${classId}/assignments/${assignmentId}/submissions`);

export const assignReviewers = ({ courseId, classId, assignmentId }) =>
  http.post(
    `/courses/${courseId}/classes/${classId}/assignments/${assignmentId}/submissions/reviewers/assign`
  );
