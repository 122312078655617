import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authService } from '../api/services';
import { setAuthToken } from '../utils/auth-token.utils';
import { getUser } from './user.store';

export const login = createAsyncThunk('auth/login', async (data, { dispatch, rejectWithValue }) => {
  try {
    const response = await authService.loginUser(data);
    const { token } = response.data.result;
    if (token) {
      setAuthToken(token);
      dispatch(getUser());
      return token;
    } else {
      return rejectWithValue('Invalid credentials');
    }
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const initialState = {
  isAuthenticated: false,
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  token: ''
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearAuthState: (state) => {
      state.isSuccess = false;
      state.isFetching = false;
      state.isError = false;
    },
    logout: () => {
      localStorage.removeItem('token');
      return initialState;
    }
  },
  extraReducers: {
    [login.fulfilled]: (state, { payload }) => {
      state.isAuthenticated = true;
      state.isFetching = false;
      state.isSuccess = true;
      state.token = payload;
      localStorage.setItem('token', payload);
      return state;
    },
    [login.pending]: (state) => {
      state.isFetching = true;
    },
    [login.rejected]: (state, { payload }) => {
      console.log('ERROR');
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    }
  }
});

export const { logout, clearAuthState } = authSlice.actions;

export default authSlice.reducer;
