import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Button, FormControl } from '@mui/material';
import { toast } from 'react-toastify';
import { classService } from '../../api/services';

const CreateClass = ({ setCurrentStep, classDetail, setClassDetail }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { courseId } = params;
  const data = { courseId };
  const { courses } = useSelector((state) => state.courses);
  const { user } = useSelector((state) => state.user);
  const [course, setCourse] = useState(null);
  const [instructors, setInstructors] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setClassDetail({ ...classDetail, [name]: value });
  };

  const getInstructors = async () => {
    try {
      const res = await classService.getInstructor(data);
      const instructRes = res.data.result;
      if (instructRes.length) {
        const currentUser = instructRes.find((instructor) => instructor.id === user.id);
        if (currentUser) {
          instructRes.splice(instructRes.indexOf(currentUser), 1);
          instructRes.unshift(currentUser);
        }
        setInstructors(instructRes);
      }
    } catch (error) {
      console.log(error);
      toast.error('Error getting instructors');
    }
  };

  useEffect(() => {
    getInstructors();
    const foundCourse = courses.find((course) => course.id == courseId);
    if (foundCourse) {
      setCourse(foundCourse);
    }
  }, []);

  const handleNext = () => {
    setCurrentStep(1);
  };

  return (
    <div>
      <div className="flex items-center mb-5">
        <div className="inline-block mr-12 text-right">Course Name</div>
        <label className="flex-1 py-2">{course?.name}</label>
      </div>
      <div className="flex items-center mb-5">
        <div className="inline-block mr-6 text-right">Instructor Name</div>
        <Select
          name="instructors"
          className="flex-1 py-2 select overflow-hidden w-full"
          multiple
          value={classDetail?.instructors ?? []}
          onChange={handleChange}>
          {instructors.length &&
            instructors.map((instructor, index) => (
              <MenuItem key={index} value={instructor.id}>
                {instructor.name}
              </MenuItem>
            ))}
        </Select>
      </div>
      <div className="flex items-center mb-10">
        <div className="inline-block mr-14 text-right">Class Name</div>
        <input
          type="text"
          className="flex-1 py-2 input input-bordered w-full"
          name="name"
          value={classDetail?.name}
          onChange={handleChange}
        />
      </div>
      <div className="flex justify-between">
        <Button variant="outlined" onClick={() => navigate(-1)}>
          Back
        </Button>
        <Button className="btn btn-primary" variant="contained" onClick={handleNext}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default CreateClass;
