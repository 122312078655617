import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { pushBreadcrumb } from '../../../store/breadcrumb.store';
import { getSubmissionReviewsReviewedByMe } from '../../../store/review.store';
import DataTable from '../../../components/DataTable';
import Toast from '../../../components/Toast';
import Breadcrumb from '../../../components/Breadcrumb';
import { formatDatetime } from '../../../utils';
import TableLink from '../../TableLink';

const ReviewByMe = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const { courseId, assignmentId } = params;
  const { user } = useSelector((state) => state.user);
  const data = {
    courseId,
    assignmentId,
    accountId: user.id
  };
  const { isFetching, reviews } = useSelector((state) => state.review);
  const [filteredReviews, setFilteredReviews] = useState([]);

  const columns = [
    {
      name: 'File Name',
      selector: (row) => row.fileName,
      sortable: true,
      width: '20%'
    },
    {
      name: 'Submitted By',
      selector: (row) => row.name,
      sortable: true,
      width: '20%'
    },
    {
      name: 'Review Start Date',
      selector: (row) => formatDatetime(row.reviewStartDate),
      sortable: true,
      width: '20%'
    },
    {
      name: 'Due Date',
      selector: (row) => formatDatetime(row.reviewEndDate),
      sortable: true,
      width: '20%'
    },
    {
      name: 'Status',
      selector: (row) =>
        !row.reviewedAt ? (!row.updatedAt ? 'Incomplete' : 'In Progress') : 'Completed',
      sortable: true,
      width: '10%'
    },
    {
      name: 'Actions',
      cell: (row) => {
        const now = new Date();
        const canReview =
          Date.parse(row.reviewStartDate) <= now && Date.parse(row.reviewEndDate) >= now;
        return !canReview ? (
          '-'
        ) : (
          <TableLink
            href={`/student/courses/${courseId}/assignments/${assignmentId}/reviews/${
              row.id
            }/feedback/me/${row.reviewedAt ? 'view' : ''}`}
            onClick={(e) => handleLinkClick(e, row.id)}>
            Review
          </TableLink>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '10%'
    }
  ];

  useEffect(() => {
    dispatch(getSubmissionReviewsReviewedByMe(data));
  }, []);

  useEffect(() => {
    setFilteredReviews(reviews);
  }, [reviews]);

  const handleFilter = (e) => {
    const { value } = e.target;
    const filteredReviews = reviews.filter((review) =>
      review.fileName.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredReviews(filteredReviews);
  };

  const handleLinkClick = (e, reviewId) => {
    e.preventDefault();

    var review = reviews.find((review) => review.id === reviewId);
    if (!review) return;

    const href = e.target.attributes.href.value;
    const breadcrumbObj = {
      label: review.name,
      href: href
    };
    dispatch(pushBreadcrumb(breadcrumbObj));
    navigate(href);
  };

  return (
    <div>
      <div className="flex justify-between mb-2">
        <div className="flex flex-col">
          <h1>Reviewed By Me</h1>
          <Breadcrumb />
        </div>
        <div>
          <input
            type="text"
            placeholder="search reviews..."
            className="input input-bordered mt-4"
            onChange={handleFilter}
          />
        </div>
      </div>
      {<DataTable data={filteredReviews} columns={columns} loading={isFetching} />}
      <Toast />
    </div>
  );
};

export default ReviewByMe;
