import React from 'react';
import { Box, Button } from '@mui/material';
import { motion } from 'framer-motion';
import DeleteIcon from '@mui/icons-material/Delete';

const ScaleResponseQuestion = ({ data, handleFormChange, handleDelete }) => {
  const { question, scaleSize, minLabel, maxLabel } = data;
  return (
    <motion.div
      animate={{
        scale: [1, 1.03, 1],
        transition: { duration: 0.3 }
      }}>
      <Box
        className="p-4 mt-4 rounded-lg shadow-sm bg-gray-50"
        sx={{ width: '100%', height: 'auto' }}>
        <div className="flex flex-col justify-center space-y-4">
          <div className="form-control">
            <label className="label">
              <span className="label-text font-bold">Question</span>
            </label>
            <input
              name="question"
              className="input input-bordered w-full"
              placeholder="Type your question here"
              value={question}
              onChange={handleFormChange}
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text font-bold">Scale Size</span>
            </label>
            <input
              type="number"
              min={1}
              max={5}
              name="scaleSize"
              value={scaleSize}
              onChange={handleFormChange}
              className="input input-bordered w-20"
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text font-bold">Min Label</span>
            </label>
            <input
              type="type"
              name="minLabel"
              value={minLabel}
              onChange={handleFormChange}
              className="input input-bordered w-48"
              placeholder="Label for min value"
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text font-bold">Max Label</span>
            </label>
            <input
              type="type"
              name="maxLabel"
              value={maxLabel}
              onChange={handleFormChange}
              className="input input-bordered w-48"
              placeholder="Label for max value"
            />
          </div>
        </div>
        <div className="flex justify-end">
          <Button className="btn btn-error mt-2" endIcon={<DeleteIcon />} onClick={handleDelete}>
            Delete
          </Button>
        </div>
      </Box>
    </motion.div>
  );
};

export default ScaleResponseQuestion;
