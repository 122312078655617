import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { submissionService } from '../api/services';

export const getSubmissions = createAsyncThunk(
  'submissions/get',
  async (data, { rejectWithValue }) => {
    try {
      const response = await submissionService.getSubmissions(data);
      const submissions = response.data.result;
      return submissions;
    } catch (error) {
      return rejectWithValue(error.responseException.exceptionMessage);
    }
  }
);

export const createSubmissions = createAsyncThunk(
  'submissions/create',
  async (data, { rejectWithValue }) => {
    try {
      const response = await submissionService.createSubmissions(data);
      const newSubmissions = response.data.result;
      return newSubmissions;
    } catch (error) {
      return rejectWithValue(error.responseException.exceptionMessage);
    }
  }
);

export const manualCreateSubmission = createAsyncThunk(
  'submissions/manual',
  async (data, { rejectWithValue }) => {
    try {
      const response = await submissionService.manualCreateSubmission(data);
      const submission = response.data.result;
      return submission;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.responseException?.exceptionMessage);
    }
  }
);

const initialState = {
  submissions: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: ''
};

export const submissionSlice = createSlice({
  name: 'submission',
  initialState,
  reducers: {
    clearSubmissionState: (state) => {
      state.isSuccess = false;
      state.isFetching = false;
      state.isError = false;
    }
  },
  extraReducers: {
    [getSubmissions.fulfilled]: (state, { payload }) => {
      state.submissions = payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getSubmissions.pending]: (state) => {
      state.isFetching = true;
    },
    [getSubmissions.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [createSubmissions.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [createSubmissions.pending]: (state) => {
      state.isFetching = true;
    },
    [createSubmissions.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [manualCreateSubmission.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [manualCreateSubmission.pending]: (state) => {
      state.isFetching = true;
    },
    [manualCreateSubmission.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    }
  }
});

export const { clearSubmissionState } = submissionSlice.actions;
export default submissionSlice.reducer;
