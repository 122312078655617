import http from '../http';

export const getSubmissionReviews = ({ courseId, classId, assignmentId, submissionId }) => {
  return http.get(
    `/courses/${courseId}/classes/${classId}/assignments/${assignmentId}/submissions/${submissionId}/reviews`
  );
};

export const getReview = ({ courseId, assignmentId, accountId }) => {
  return http.get(
    `/courses/${courseId}/assignments/${assignmentId}/review/peers?accountId=${accountId}`
  );
};

export const getPeerReview = ({ courseId, assignmentId, reviewId }) => {
  return http.get(`/courses/${courseId}/assignments/${assignmentId}/reviews/${reviewId}/feedback`);
};

export const getSubmissionReviewsReviewedByMe = ({ courseId, assignmentId, accountId }) => {
  return http.get(
    `/courses/${courseId}/assignments/${assignmentId}/reviews/me?accountId=${accountId}`
  );
};

export const saveReview = ({ courseId, assignmentId, reviewId }, data) => {
  return http.put(
    `/courses/${courseId}/assignments/${assignmentId}/reviews/${reviewId}/feedback/me`,
    JSON.stringify(data)
  );
};

export const submitReview = ({ courseId, assignmentId, reviewId }, data) => {
  return http.post(
    `/courses/${courseId}/assignments/${assignmentId}/reviews/${reviewId}/feedback/me`,
    JSON.stringify(data)
  );
};
