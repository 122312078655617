import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDatetime } from '../../../utils';
import DataTable from '../../../components/DataTable';
import { useLocation, useNavigate, useParams } from 'react-router';
import Breadcrumb from '../../../components/Breadcrumb';
import { pushBreadcrumb } from '../../../store/breadcrumb.store';
import { clearAssignmentState, getAssignmentByCourse } from '../../../store/assignment.store';
import TableLink from '../../TableLink';

const StudentAssignments = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { courseId } = params;
  const { user } = useSelector((state) => state.user);
  const data = {
    courseId,
    accountId: user.id
  };

  const { assignments, isFetching } = useSelector((state) => state.assignment);

  const columns = [
    {
      name: 'Assignment Name',
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: 'No. of Submissions to Review',
      selector: (row) => row.countsubmission,
      sortable: true
    },
    {
      name: 'Review Start Date',
      selector: (row) => formatDatetime(row.reviewStartDate),
      sortable: true
    },
    {
      name: 'Review Due Date',
      selector: (row) => formatDatetime(row.reviewEndDate),
      sortable: true
    },
    {
      name: 'My Assigned Reviews',
      cell: (row) => (
        <div key={row.id}>
          <TableLink
            className="p-1"
            href={`${row.id}/reviews/me`}
            onClick={(e) => handleSelfReviewClick(e, row.id)}>
            View
          </TableLink>
        </div>
      )
    },
    {
      name: 'Reviewed By Others',
      cell: (row) => (
        <div key={row.id}>
          <TableLink
            className="p-1"
            href={`${row.id}/reviews/peers`}
            onClick={(e) => handleSelfReviewClick(e, row.id)}>
            View
          </TableLink>
        </div>
      )
    }
  ];
  const [filteredData, setFilteredData] = useState([]);

  const handleSearch = (e) => {
    const { value } = e.target;
    const filteredData = assignments.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filteredData);
  };

  useEffect(() => {
    dispatch(clearAssignmentState());
    dispatch(getAssignmentByCourse(data));
  }, []);

  useEffect(() => {
    setFilteredData(assignments);
  }, [assignments]);

  const handleSelfReviewClick = (e, id) => {
    e.preventDefault();

    var assignment = assignments.find((assignment) => assignment.id === id);
    if (!assignment) return;

    const href = e.target.attributes.href.value;
    const breadcrumbObj = {
      label: assignment.name,
      href: location.pathname + '/' + href
    };
    dispatch(pushBreadcrumb(breadcrumbObj));
    navigate(href);
  };

  return (
    <div>
      <div className="flex justify-between mb-2">
        <div className="flex flex-col">
          <h1>Assignments</h1>
          <Breadcrumb />
        </div>
        <div>
          <input
            type="text"
            placeholder="search assignment..."
            className="input input-bordered mt-4"
            onChange={handleSearch}
          />
        </div>
      </div>
      <DataTable data={filteredData} columns={columns} isFetching={isFetching} />
    </div>
  );
};

export default StudentAssignments;
