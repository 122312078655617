import { Button, LinearProgress } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import { userService } from '../../api/services';
import { swalConfirmation, swalError, swalSuccess } from '../../utils';
import DataTable from '../DataTable';
import FileDropzone from '../FileDropzone';
import Toast from '../Toast';

const ImportClasslist = ({ students, setStudents, handleCreateClass, disableCreateBtn }) => {
  const [file, setFile] = useState(null);
  const [showProgress, setShowProgress] = useState(false);
  const [disableImportBtn, setDisableImportBtn] = useState(false);
  const columns = [
    {
      name: 'Student ID',
      selector: (row) => row.id,
      sortable: true
    },
    {
      name: 'Student Name',
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: 'Student Email',
      selector: (row) => row.email,
      sortable: true,
      grow: 2
    }
  ];

  const handleReset = () => {
    setFile(null);
    setStudents([]);
  };

  const handleImport = async () => {
    if (!file) {
      toast.error('Please select a file');
      return;
    }
    const { isConfirmed } = await swalConfirmation('Are you sure you want to import this file?');
    if (!isConfirmed) return;

    try {
      setDisableImportBtn(true);
      setShowProgress(true);

      const formData = new FormData();
      formData.append('file', file);
      const response = await userService.importUsers(formData);
      const students = response.data.result;

      swalSuccess(`Successfully imported ${students.length} students`);
      setStudents(students);
      setDisableImportBtn(false);
      setShowProgress(false);
    } catch (error) {
      console.error(error);
      swalError('Error importing file');
      setShowProgress(false);
      setDisableImportBtn(false);
    }
  };

  const showErrorMsg = () => {
    toast.error('File type not supported');
  };

  return (
    <div className="flex flex-col space-y-6 text-center">
      <h1>Import Class List</h1>
      <p className="text-gray-500">Upload your csv file to import classlist</p>
      {students.length ? (
        <Fragment>
          <DataTable data={students} columns={columns} />
          <div className="flex justify-between">
            <Button className="btn btn-warning" variant="contained" onClick={handleReset}>
              Reset
            </Button>
            <Button
              className="btn btn-primary mb-4"
              disabled={disableCreateBtn}
              onClick={handleCreateClass}>
              Create Class
            </Button>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <FileDropzone
            accept={{ 'text/csv': [] }}
            maxFiles={1}
            file={file}
            handleDropAccepted={(files) => setFile(files[0])}
            handleDropRejected={showErrorMsg}
          />
          {showProgress && <LinearProgress />}
          <Button
            className="btn btn-primary"
            variant="contained"
            disabled={disableImportBtn}
            onClick={handleImport}>
            Import Class List
          </Button>
        </Fragment>
      )}

      <Toast />
    </div>
  );
};

export default ImportClasslist;
