import { CircularProgress } from '@mui/material';
import React from 'react';
import Table from 'react-data-table-component';

const customStyles = {
  headCells: {
    style: {
      fontSize: '0.9rem',
      fontWeight: 'bold',
      backgroundColor: '#4B4CDC',
      color: '#fff'
    }
  },
  pagination: {
    style: {
      fontSize: '0.9rem',
      minHeight: '10px',
      display: 'flex',
      justifyContent: 'center',
      fontWeight: 'bold'
    },
    pageButtonStyle: {
      borderRadius: '80%',
      cursor: 'pointer',
      height: '40px',
      width: '40px',
      padding: '8px',
      margin: 'px',
      transition: '0.4s'
    }
  }
};

const DataTable = ({ data, columns, loading }) => {
  return (
    <div className="mt-8 pr-table">
      <Table
        columns={columns}
        data={data}
        progressPending={loading}
        progressComponent={<CircularProgress className="my-4" />}
        customStyles={customStyles}
        pagination
        highlightOnHover
        pointerOnHover
      />
    </div>
  );
};

export default DataTable;
