import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { assignReviewers } from '../../api/services/submission.service';
import { pushBreadcrumb } from '../../store/breadcrumb.store';
import { clearSubmissionState, getSubmissions } from '../../store/submission.store';
import { formatDatetime, swalConfirmation, swalSuccess } from '../../utils';

import ImportSubmissionModal from '../../components/Submission/ImportSubmissionModal';
import ManualCreateSubmissionModal from '../../components/Submission/ManualCreateSubmissionModal';
import Toast from '../../components/Toast';
import TableLink from '../TableLink';
import Breadcrumb from '../../components/Breadcrumb';
import DataTable from '../../components/DataTable';

const Submissions = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [showAssignReviewersBtn, setShowAssignReviewersBtn] = useState(false);
  const [disableImportBtns, setDisableImportBtns] = useState(false);
  const { courseId, classId, assignmentId } = params;
  const data = {
    courseId,
    classId,
    assignmentId
  };
  const { submissions, isFetching } = useSelector((state) => state.submission);
  const columns = [
    {
      name: 'Student ID',
      selector: (row) => row.studentId,
      sortable: true
    },
    {
      name: 'Student Name',
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: 'File Name',
      cell: (row) => (
        <TableLink href={row.fileUrl} target="_blank">
          {row.fileName}
        </TableLink>
      ),
      selector: (row) => row.fileName,
      sortable: true
    },
    {
      name: 'Submission Date',
      selector: (row) => formatDatetime(row.submittedAt),
      sortable: true
    },
    {
      name: 'Review Status',
      selector: (row) =>
        !row.totalReviews ? 'Unassigned' : `${row.totalReviewed ?? 0}/${row.totalReviews ?? 0}`,
      center: true
    },
    {
      name: 'Actions',
      cell: (row) =>
        !row.totalReviews ? (
          '-'
        ) : (
          <TableLink
            className="btn btn-link"
            href={`submissions/${row.submissionId}/reviews`}
            onClick={(e) => handleViewReviewClick(e, row.submissionId)}>
            View
          </TableLink>
        )
    }
  ];

  const [filteredData, setFilteredData] = useState([]);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openManualImportModal, setOpenManualImportModal] = useState(false);

  const handleSearch = (e) => {
    const { value } = e.target;
    const filteredData = submissions.filter(
      (item) =>
        item.studentId.toLowerCase().includes(value.toLowerCase()) ||
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.fileName.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filteredData);
  };

  const handleViewReviewClick = (e, submissionId) => {
    e.preventDefault();
    const submission = submissions.find((item) => item.submissionId === submissionId);
    if (!submission) {
      toast.error('Submission not found');
      return;
    }

    const breadcrumbObj = {
      label: `Reviewers for ${submission.name}`,
      href: `${location.pathname}/${submissionId}/reviews`
    };

    dispatch(pushBreadcrumb(breadcrumbObj));
    navigate(breadcrumbObj.href);
  };

  const handleAssignReviewers = async () => {
    const { isConfirmed } = await swalConfirmation('Are you sure you want to assign reviewers?');
    if (!isConfirmed) return;

    setShowAssignReviewersBtn(false);
    try {
      await assignReviewers(data);
      await swalSuccess('Reviewers assigned successfully');
      dispatch(getSubmissions(data));
    } catch (error) {
      toast.error(error.message);
      setShowAssignReviewersBtn(true);
    }
  };

  useEffect(() => {
    dispatch(clearSubmissionState());
    dispatch(getSubmissions(data));
  }, []);

  useEffect(() => {
    setFilteredData(submissions);
    if (submissions.length) {
      const unassignedReviewers = submissions.find((submission) => !submission.totalReviews);
      if (unassignedReviewers && unassignedReviewers !== undefined) setShowAssignReviewersBtn(true);
      const startedReview =
        submissions.some((submission) => submission.totalReviewed > 0) &&
        submissions.some((submission) => submission.totalReviews > 0);
      setDisableImportBtns(startedReview);
    }
  }, [submissions]);

  return (
    <div>
      <div>
        <h1>Submissions</h1>
        <Breadcrumb />
      </div>
      <div className="flex mt-4 justify-between">
        <div className="flex w-full space-x-2">
          <Button
            className="btn btn-primary"
            variant="contained"
            disabled={disableImportBtns}
            onClick={() => setOpenImportModal(true)}>
            Import Submissions
          </Button>
          <Button
            className="btn btn-primary"
            variant="contained"
            disabled={disableImportBtns}
            onClick={() => setOpenManualImportModal(true)}>
            Manual Create Submission
          </Button>
          {showAssignReviewersBtn && (
            <Button className="btn btn-success" variant="contained" onClick={handleAssignReviewers}>
              Assign Reviewers
            </Button>
          )}
        </div>
        <div>
          <input
            type="text"
            placeholder="search submission..."
            className="input input-bordered"
            onChange={handleSearch}
          />
        </div>
      </div>
      <DataTable data={filteredData} columns={columns} loading={isFetching} />
      <ImportSubmissionModal open={openImportModal} handleClose={() => setOpenImportModal(false)} />
      <ManualCreateSubmissionModal
        open={openManualImportModal}
        handleClose={() => setOpenManualImportModal(false)}
      />
      <Toast />
    </div>
  );
};

export default Submissions;
