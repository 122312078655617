import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';

const AssignmentDetails = ({ setCurrentStep, handleFormInputChange, form }) => {
  const navigate = useNavigate();
  return (
    <div className="space-y-10">
      <div className="grid grid-cols-3 gap-4 items-center">
        <div>Assignment Name</div>
        <div className="col-span-2">
          <input
            type="text"
            className="input input-bordered w-full"
            name="name"
            placeholder="Enter Assignment Name"
            value={form?.name}
            onChange={handleFormInputChange}
          />
        </div>
        <div>Description</div>
        <div className="col-span-2">
          <input
            type="text"
            className="input input-bordered w-full"
            name="instruction"
            placeholder="Enter Description"
            value={form?.instruction}
            onChange={handleFormInputChange}
          />
        </div>
        <div>Start Date</div>
        <div className="col-span-2">
          <input
            type="datetime-local"
            className="input input-bordered w-full"
            name="reviewStartDate"
            value={form?.reviewStartDate}
            onChange={handleFormInputChange}
          />
        </div>
        <div>End Date</div>
        <div className="col-span-2">
          <input
            type="datetime-local"
            className="input input-bordered w-full"
            name="reviewEndDate"
            value={form?.reviewEndDate}
            onChange={handleFormInputChange}
          />
        </div>
      </div>
      <div className="flex justify-between">
        <Button variant="outlined" onClick={() => navigate(-1)}>
          Back
        </Button>
        <Button className="btn btn-primary" onClick={() => setCurrentStep(1)}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default AssignmentDetails;
