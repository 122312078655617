import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useState } from 'react';
import { getWordCount } from '../../utils';

const FreeResponseReview = ({ data, handleFormChange }) => {
  const { questionId, question, minLength, answer } = data;
  const [currentWordCount, setCurrentWordCount] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState(answer);

  const handleChange = (e) => {
    const { value } = e.target;
    const totalWordCount = getWordCount(value);
    setCurrentWordCount(totalWordCount);
    setCurrentAnswer(value);
    handleFormChange(e);
  };

  useEffect(() => {
    if (answer?.length > 0) {
      setCurrentWordCount(getWordCount(answer));
    }
  }, []);

  return (
    <Box
      className="h-auto p-4 mt-4 bg-gray-50 text-center flex flex-col justify-center rounded-md drop-shadow-sm"
      sx={{ width: '100%', height: 'auto' }}>
      <span className="font-bold my-10">{question}</span>
      <div className="text-right">
        <span className="text-sm">Min Words: {minLength}</span>
      </div>
      <textarea
        name="answer"
        className="textarea textarea-bordered w-full"
        placeholder="Type your feedback here..."
        onChange={handleChange}
        rows={8}
        value={currentAnswer}
      />
      <div className="text-right">
        <span className={currentWordCount >= minLength ? 'text-green-600' : 'text-red-600'}>
          {currentWordCount} words
        </span>
      </div>
    </Box>
  );
};

export default FreeResponseReview;
