import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal } from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { swalConfirmation, swalSuccess } from '../../utils';
import { toast } from 'react-toastify';
import Toast from '../Toast';
import { useDispatch, useSelector } from 'react-redux';
import { getClass } from '../../store/class.store';
import { useParams } from 'react-router';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { getInstructor } from '../../store/instructor.store';
import { classService } from '../../api/services';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 0.4,
  bgcolor: 'white',
  boxShadow: 24,
  p: 4
};

const UpdateClassModal = ({ open, handleClose, row }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { courseId } = params;
  const { courses } = useSelector((state) => state.courses);
  const { instructors } = useSelector((state) => state.instructor);
  const [course, setCourse] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const initialFormState = { instructors: [], name: '' };
  const [form, setForm] = useState(initialFormState);

  /**
   * Close modal only when user clicks on the 'x' button.
   *
   * Resets local states before closing.
   *
   * @param {*} _ - unused parameter
   * @param {*} reason - reason for closing modal
   */
  const handleModalClose = async (_, reason) => {
    if (reason !== 'backdropClick') {
      const { isConfirmed } = await swalConfirmation(
        'Closing this modal will discard all changes. Are you sure?'
      );
      if (isConfirmed) {
        resetStates();
        handleClose();
      }
    }
  };

  const handleUpdate = async () => {
    if (!validateForm()) return;
    const { isConfirmed } = await swalConfirmation('Are you sure you want to update this class?');
    if (!isConfirmed) return;
    setDisableButton(true);
    const updateDetail = {
      courseId,
      classId: parseInt(row.classId),
      classes: form
    };

    // No need to use dispatch here, because we are not updating the state
    try {
      console.log(updateDetail);
      await classService.updateClass(updateDetail);
      await swalSuccess('Class updated successfully!');
      dispatch(getClass({ courseId }));
      handleClose();
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong. Please try again.');
    }
    setDisableButton(false);
  };

  /**
   * Validate form data.
   *
   * @returns {boolean} true if form is valid, false otherwise
   */
  const validateForm = () => {
    if (!form.name || !form.instructors.length) {
      toast.error('Please fill in all fields.');
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (open) {
      if (!instructors.length) dispatch(getInstructor({ courseId }));
      const foundCourse = courses.find((course) => course.id == courseId);
      if (foundCourse) {
        setCourse(foundCourse);
      }
      const instructorIds = row.instructors.map((item) => item.id);
      console.log(instructorIds);
      setForm({ instructors: instructorIds, name: row.className });
    }
  }, [open]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const resetStates = () => {
    setDisableButton(false);
    setForm(initialFormState);
  };

  return (
    <Fragment>
      <Modal open={open} disableEscapeKeyDown>
        <Box sx={style}>
          <CloseIcon
            className="absolute right-0 top-0 m-2 cursor-pointer transition ease-in-out delay-130 hover:scale-110 duration-300"
            onClick={handleModalClose}
          />
          <div className="flex flex-col space-y-6 text-center">
            <div>
              <h1>Update Class Details</h1>
            </div>
            <div className="grid grid-cols-3 gap-4 items-center">
              <div>Course Name</div>
              <div className="col-span-2">
                <label className="flex-1 py-2">{course?.name}</label>
              </div>
              <div>Instructor Name</div>
              <div className="col-span-2">
                <Select
                  name="instructors"
                  className="flex-1 py-2 select overflow-hidden w-full"
                  multiple
                  value={form?.instructors}
                  onChange={handleChange}>
                  {instructors.map((instructor, index) => (
                    <MenuItem key={index} value={instructor.id}>
                      {instructor.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div>Class Name</div>
              <div className="col-span-2">
                <input
                  type="text"
                  className="input input-bordered w-full"
                  name="name"
                  value={form.name}
                  onChange={handleChange}
                />
              </div>
            </div>

            <Button
              className="btn btn-primary"
              variant="contained"
              disabled={disableButton}
              onClick={handleUpdate}>
              Update Class
            </Button>
          </div>
        </Box>
      </Modal>
      <Toast />
    </Fragment>
  );
};

export default UpdateClassModal;
