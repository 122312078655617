import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { reviewService } from '../api/services';

export const getSubmissionReviews = createAsyncThunk(
  'reviews/get',
  async (data, { rejectWithValue }) => {
    try {
      const response = await reviewService.getSubmissionReviews(data);
      const reviews = response.data.result;
      return reviews;
    } catch (error) {
      return rejectWithValue(error.responseException.exceptionMessage);
    }
  }
);

export const getReview = createAsyncThunk('reviews/get', async (data, { rejectWithValue }) => {
  try {
    const response = await reviewService.getReview(data);
    const reviews = response.data.result;
    return reviews;
  } catch (error) {
    return rejectWithValue(error.responseException.exceptionMessage);
  }
});

export const getPeerReview = createAsyncThunk('reviews/get', async (data, { rejectWithValue }) => {
  try {
    const response = await reviewService.getPeerReview(data);
    const reviews = response.data.result;
    return reviews;
  } catch (error) {
    return rejectWithValue(error.responseException.exceptionMessage);
  }
});
export const getSubmissionReviewsReviewedByMe = createAsyncThunk(
  'reviews/getReviewedByMe',
  async (data, { rejectWithValue }) => {
    try {
      const response = await reviewService.getSubmissionReviewsReviewedByMe(data);
      const reviews = response.data.result;
      return reviews;
    } catch (error) {
      return rejectWithValue(error.responseException.exceptionMessage);
    }
  }
);

const initialState = {
  reviews: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: ''
};

export const reviewSlice = createSlice({
  name: 'review',
  initialState,
  reducers: {
    clearReviewState: (state) => {
      state.isSuccess = false;
      state.isFetching = false;
      state.isError = false;
    }
  },
  extraReducers: {
    [getSubmissionReviews.fulfilled]: (state, { payload }) => {
      state.reviews = payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getSubmissionReviews.pending]: (state) => {
      state.isFetching = true;
    },
    [getSubmissionReviews.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [getSubmissionReviewsReviewedByMe.fulfilled]: (state, { payload }) => {
      state.reviews = payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getSubmissionReviewsReviewedByMe.pending]: (state) => {
      state.isFetching = true;
    },
    [getSubmissionReviewsReviewedByMe.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [getSubmissionReviews.fulfilled]: (state, { payload }) => {
      state.reviews = payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getSubmissionReviews.pending]: (state) => {
      state.isFetching = true;
    },
    [getSubmissionReviews.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [getReview.fulfilled]: (state, { payload }) => {
      state.reviews = payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getReview.pending]: (state) => {
      state.isFetching = true;
    },
    [getReview.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [getPeerReview.fulfilled]: (state, { payload }) => {
      state.reviews = payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getPeerReview.pending]: (state) => {
      state.isFetching = true;
    },
    [getPeerReview.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    }
  }
});

export const { clearReviewState } = reviewSlice.actions;
export default reviewSlice.reducer;
