import React, { useEffect } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useState } from 'react';
import Toast from '../Toast';
import FreeResponseQuestion from './FreeResponseQuestion';
import ScaleResponseQuestion from './ScaleResponseQuestion';
import { toast } from 'react-toastify';
import { swalConfirmation, swalTextInput } from '../../utils';
import { QuestionType } from '../../constants';
import ImportRubricModal from './ImportRubricModal';
import AddIcon from '@mui/icons-material/Add';
import { getrubric } from '../../api/services/assignment.service';
import { useParams } from 'react-router';

const CreateRubric = ({
  setQuestions,
  handleCreate,
  setCurrentStep,
  form,
  isLibrarycheck,
  handleFormInputChange
}) => {
  const freeResponseQnDefault = {
    qnTypeId: QuestionType.FREE_RESPONSE,
    question: '',
    minLength: 100
  };
  const scaleResponseQnDefault = {
    qnTypeId: QuestionType.SCALE_RESPONSE,
    question: '',
    scaleSize: 5,
    minLabel: '',
    maxLabel: ''
  };
  const [openImportRubricModal, setOpenImportRubricModal] = useState(false);
  const [selectedRubric, setSelectedRubric] = useState('');
  const [selectedRubricQns, setSelectedRubricQns] = useState([]);
  const [selectedQnType, setSelectedQnType] = useState(QuestionType.FREE_RESPONSE);
  const [qnInputFields, setQnInputFields] = useState([freeResponseQnDefault]);
  const [rubricLib, setRubricLib] = useState([]);

  const params = useParams();
  const { courseId } = params;
  const data = {
    courseId
  };
  // TODO: retrieve from api
  // const rubricLib = [
  //   {
  //     rubricId: 1,
  //     rubricName: 'ICT2109 Rubric',
  //     questions: [
  //       { ...freeResponseQnDefault, question: 'Question 1', minLength: 100 },
  //       { ...freeResponseQnDefault, question: 'Question 2', minLength: 100 },
  //       {
  //         ...scaleResponseQnDefault,
  //         question: 'Question 3',
  //         scaleSize: 5,
  //         minLabel: 'Terrible',
  //         maxLabel: 'Excellent'
  //       }
  //     ]
  //   },
  //   {
  //     rubricId: 2,
  //     rubricName: 'ICT2109 Rubric 2',
  //     questions: [
  //       freeResponseQnDefault,
  //       freeResponseQnDefault,
  //       scaleResponseQnDefault,
  //       freeResponseQnDefault
  //     ]
  //   },
  //   {
  //     rubricId: 3,
  //     rubricName: 'Sample Rubric',
  //     questions: [freeResponseQnDefault, scaleResponseQnDefault]
  //   }
  // ];

  /**
   * Import rubric from existing rubric library.
   */
  const handleImportRubric = async () => {
    const rubric = rubricLib.find((rubric) => rubric.rubricId === parseInt(selectedRubric));
    if (!rubric) {
      toast.error('Rubric not found');
      return;
    }
    const { isConfirmed } = await swalConfirmation('Are you sure you want to import this rubric?');
    if (!isConfirmed) return;
    setOpenImportRubricModal(true);
    setSelectedRubricQns(rubric.questions);
  };

  /**
   * Add a new question to the list of questions.
   */
  const handleAddQuestion = () => {
    switch (selectedQnType) {
      case QuestionType.FREE_RESPONSE:
        setQnInputFields([...qnInputFields, freeResponseQnDefault]);
        break;
      case QuestionType.SCALE_RESPONSE:
        setQnInputFields([...qnInputFields, scaleResponseQnDefault]);
        break;
      default:
        setQnInputFields([...qnInputFields, freeResponseQnDefault]);
        break;
    }
  };

  const handleCreateRubric = async () => {
    if (!isValidInput()) {
      toast.error('Please fill in all fields');
      return;
    }
    const { value: rubricName } = await swalTextInput('Enter a name for the rubric');
    console.log(rubricName, qnInputFields);

    // TODO: show modal to input rubric name
    // TODO: save to api
  };

  /**
   * Set value to the question input field.
   *
   * @param {*} e event
   * @param {*} index index of the question
   */
  const handleQnInputFieldChange = (e, index) => {
    const { name, value } = e.target;
    let data = [...qnInputFields];
    data[index][name] = value;
    setQnInputFields(data);
  };

  /**
   * Remove question from list.
   *
   * @param {number} index index of question in qnInputFields
   * @returns {boolean} true if question is valid
   */
  const handleDeleteQuestion = (index) => {
    if (qnInputFields.length === 1) {
      toast.error('Must have at least one question');
      return;
    }
    const newQnInputFields = [...qnInputFields];
    newQnInputFields.splice(index, 1);
    setQnInputFields(newQnInputFields);
  };

  const importRubricQuestions = (questions) => {
    setQnInputFields([...qnInputFields, ...questions]);
  };

  /**
   * Validate all input fields
   */
  const isValidInput = () => {
    qnInputFields.forEach((qn) => {
      if (qn.qnTypeId === QuestionType.FREE_RESPONSE) {
        if (!qn.question || !qn.minLength) return false;
      } else {
        if (!qn.question || !qn.scaleSize || !qn.minLabel || !qn.maxLabel) return false;
      }
    });
    return true;
  };

  const getRubrics = async () => {
    try {
      const res = await getrubric(data);
      const rubricsResult = res.data?.result;
      if (rubricsResult.length) {
        setRubricLib(rubricsResult);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRubrics();
  }, []);

  useEffect(() => {
    setQuestions(qnInputFields);
  }, [qnInputFields]);

  return (
    <div className="mt-4">
      <div className="flex justify-between">
        <div className="w-1/2 flex space-x-2">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Choose from library</InputLabel>
            <Select
              className="h-12"
              label="Choose from library"
              value={selectedRubric}
              onChange={(e) => setSelectedRubric(e.target.value)}>
              {rubricLib.map((rubric) => (
                <MenuItem key={rubric.rubricId} value={rubric.rubricId}>
                  {rubric.rubricName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button className="btn btn-primary" onClick={handleImportRubric}>
            Import
          </Button>
        </div>
        <div className="w-40">
          <div className="form-control">
            <label className="label cursor-pointer">
              <input
                type="checkbox"
                className="checkbox checkbox-primary"
                checked={form?.IsLibrary}
                onChange={isLibrarycheck}
              />
              <span className="label-text text-lg mr-1">Save to Library</span>
            </label>
          </div>
          <input
            type="text"
            className="input input-bordered w-full"
            name="rubricName"
            placeholder="Enter Rubric Name"
            value={form?.rubricName}
            onChange={handleFormInputChange}
            hidden={!form?.IsLibrary}
          />
        </div>
      </div>
      {qnInputFields.map((input, index) =>
        input.qnTypeId === QuestionType.FREE_RESPONSE ? (
          <FreeResponseQuestion
            key={index}
            data={input}
            handleFormChange={(e) => handleQnInputFieldChange(e, index)}
            handleDelete={() => handleDeleteQuestion(index)}
          />
        ) : (
          <ScaleResponseQuestion
            key={index}
            data={input}
            handleFormChange={(e) => handleQnInputFieldChange(e, index)}
            handleDelete={() => handleDeleteQuestion(index)}
          />
        )
      )}
      <div className="flex mt-4 space-x-2">
        <Select
          className="h-12"
          defaultValue={QuestionType.FREE_RESPONSE}
          onChange={(e) => setSelectedQnType(e.target.value)}>
          <MenuItem value={QuestionType.FREE_RESPONSE}>Free Response</MenuItem>
          <MenuItem value={QuestionType.SCALE_RESPONSE}>Scale Response</MenuItem>
        </Select>
        <Button className="btn btn-primary" endIcon={<AddIcon />} onClick={handleAddQuestion}>
          Add Question
        </Button>
      </div>

      <div className="flex justify-between mt-6">
        <Button variant="outlined" onClick={() => setCurrentStep(1)}>
          Back
        </Button>
        <Button className="btn btn-primary" onClick={handleCreate}>
          Save & Finish
        </Button>
      </div>
      <ImportRubricModal
        questions={selectedRubricQns}
        open={openImportRubricModal}
        handleClose={() => setOpenImportRubricModal(false)}
        handleImportQuestions={(questions) => importRubricQuestions(questions)}
      />
      <Toast />
    </div>
  );
};

export default CreateRubric;
