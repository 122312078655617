import { Button, Link } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDatetime } from '../../../utils';
import DataTable from '../../../components/DataTable';
import { useLocation, useNavigate, useParams } from 'react-router';
import Breadcrumb from '../../../components/Breadcrumb';
//import { pushBreadcrumb } from '../../../store/breadcrumb.store';
import {
  clearAssignmentState,
  getAssignmentByCourse,
  deleteAssignment,
  duplicateAssignment
} from '../../../store/assignment.store';
import { swalConfirmation, swalSuccess } from '../../../utils';
import TableLink from '../../TableLink';
import { toast } from 'react-toastify';

import UpdateAssignmentModal from '../../../components/Assignment/UpdateAssignmentModal';

const CourseAssignments = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { courseId } = params;
  const data = {
    courseId,
    accountId: 'null'
  };

  const { assignments, isFetching, isError, isSuccess, errorMessage } = useSelector(
    (state) => state.assignment
  );

  const columns = [
    {
      name: 'Assignment Name',
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: 'No. of Submissions',
      selector: (row) => row.countsubmission,
      sortable: true
    },
    {
      name: 'Review Start Date',
      selector: (row) => formatDatetime(row.reviewStartDate),
      sortable: true
    },
    {
      name: 'Review Due Date',
      selector: (row) => formatDatetime(row.reviewEndDate),
      sortable: true
    },
    /**{
      name: 'Duplicate Assignment',
      cell: (row) => <TableLink onClick={() => handleDuplicate(row.id)}>Duplicate</TableLink>
    },*/
    {
      name: 'Actions',
      cell: (row) => (
        <div key={row.id}>
          <TableLink
            className="text-warning p-1"
            onClick={() => [selectedRow(row), setOpenUpdateAssignmentModal(true)]}>
            Edit
          </TableLink>
          |
          <TableLink className="text-error p-1" onClick={() => handleDelete(row.id)}>
            Delete
          </TableLink>
        </div>
      ),
      center: true
    }
  ];
  const [filteredData, setFilteredData] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState([]);
  const [openDeleteAssignmentModal, setOpenDeleteAssignmentModal] = useState(false);
  const [openUpdateAssignmentModal, setOpenUpdateAssignmentModal] = useState(false);
  const [openDuplicateAssignmentModal, setOpenDuplicateAssignmentModal] = useState(false);

  const handleSearch = (e) => {
    const { value } = e.target;
    const filteredData = assignments.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filteredData);
  };
  /** 
  const handleAssignmentClick = (e, id) => {
    e.preventDefault();

    var assignment = assignments.find((assignment) => assignment.id === id);
    if (!assignment) return;

    const href = e.target.attributes.href.value;
    const breadcrumbObj = {
      label: assignment.name,
      href: location.pathname + '/' + href
    };
    dispatch(pushBreadcrumb(breadcrumbObj));
    navigate(href);
  };
*/
  const handleDelete = async (id) => {
    setOpenDeleteAssignmentModal(true);
    const { isConfirmed } = await swalConfirmation(
      'Are you sure you want to delete this assignment?'
    );
    if (!isConfirmed) return;
    dispatch(clearAssignmentState());
    dispatch(
      deleteAssignment({
        courseId: data.courseId,
        assignmentId: id
      })
    );
  };

  const handleDuplicate = async (id) => {
    setOpenDuplicateAssignmentModal(true);
    const { isConfirmed } = await swalConfirmation(
      'Are you sure you want to duplicate this assignment?'
    );
    if (!isConfirmed) return;
    dispatch(clearAssignmentState());
    dispatch(
      duplicateAssignment({
        courseId: data.courseId,
        assignmentId: id
      })
    );
  };

  const selectedRow = (data) => {
    const selectedAssignment = data;
    setSelectedAssignment(selectedAssignment);
  };

  useEffect(() => {
    dispatch(clearAssignmentState());
    dispatch(getAssignmentByCourse(data));
  }, []);

  useEffect(() => {
    setFilteredData(assignments);
  }, [assignments]);

  useEffect(() => {
    if (!openDeleteAssignmentModal) return;
    if (isSuccess) {
      swalSuccess('Assignment deleted successfully').then(() => {
        dispatch(clearAssignmentState());
        setOpenDeleteAssignmentModal(false);
        dispatch(getAssignmentByCourse(data));
      });
    } else if (isError) {
      toast.error(errorMessage ?? 'Error deleting assignment');
      dispatch(clearAssignmentState());
      setOpenDeleteAssignmentModal(false);
    }
  }, [isSuccess, isError]);
  /** 
  useEffect(() => {
    if (!openDuplicateAssignmentModal) return;
    if (isSuccess) {
      swalSuccess('Assignment duplicated successfully').then(() => {
        dispatch(clearAssignmentState());
        setOpenDuplicateAssignmentModal(false);
        dispatch(getAssignmentByCourseId(data));
      });
    } else if (isError) {
      toast.error(errorMessage ?? 'Error duplicating assignment');
      dispatch(clearAssignmentState());
      setOpenDuplicateAssignmentModal(false);
    }
  }, [isSuccess, isError]);
*/
  const navigateToCreateAssignment = () => {
    navigate(location.pathname + '/create');
  };

  return (
    <div>
      <div>
        <h1>Assignments</h1>
        <Breadcrumb />
      </div>
      <div className="flex mt-4 justify-between">
        <div className="flex w-full space-x-2">
          <Button
            className="btn btn-primary"
            variant="contained"
            onClick={navigateToCreateAssignment}>
            Create Assignment
          </Button>
        </div>
        <div>
          <input
            type="text"
            placeholder="search assignment..."
            className="input input-bordered"
            onChange={handleSearch}
          />
        </div>
      </div>
      <DataTable data={filteredData} columns={columns} isFetching={isFetching} />
      <UpdateAssignmentModal
        open={openUpdateAssignmentModal}
        handleClose={() => setOpenUpdateAssignmentModal(false)}
        row={selectedAssignment}
      />
    </div>
  );
};

export default CourseAssignments;
