import http from '../http';

export const loginUser = (data) => {
  const body = JSON.stringify(data);
  return http.post('/auth/login', body);
};

export const changePassword = (data) => {
  const body = JSON.stringify(data);
  return http.post('/auth/change-password', body);
};

export const requireChangePassword = (data) => {
  const body = JSON.stringify(data);
  return http.post('/auth/require-password-change', body);
};
