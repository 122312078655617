import { Breadcrumbs, Link, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { spliceBreadcrumbsAfterIndex } from '../store/breadcrumb.store';

const Breadcrumb = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { breadcrumbs: breadcrumbList } = useSelector((state) => state.breadcrumb);

  const handleClick = (e) => {
    e.preventDefault();
    const href = e.target.attributes.href.value;
    const breadcrumbIndex = breadcrumbList.findIndex((breadcrumb) => breadcrumb.href === href);
    if (breadcrumbIndex === -1) return;

    dispatch(spliceBreadcrumbsAfterIndex(breadcrumbIndex));
    navigate(href);
  };

  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      {breadcrumbList.map((breadcrumb, index) =>
        index !== breadcrumbList.length - 1 ? (
          <Link
            underline="hover"
            color="inherit"
            key={index}
            href={breadcrumb.href}
            onClick={handleClick}>
            {breadcrumb.label}
          </Link>
        ) : (
          <Typography key={index} color="text.primary">
            {breadcrumb.label}
          </Typography>
        )
      )}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
