import http from '../http';

export const getUserCourses = (accountId) => {
  return http.get(`/courses?accountId=${accountId}`);
};

export const createCourses = (data, accountId) =>
  http.post(`/courses?accountId=${accountId}`, data);

export const updateCourses = (data, courseId) => http.put(`/courses/${courseId}`, data);

export const deleteCourses = (courseId) => http.delete(`/courses/${courseId}`);
