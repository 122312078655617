import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import http from '../api/http';

import authReducer from './auth.store';
import breadcrumbReducer from './breadcrumb.store';
import userReducer from './user.store';
import submissionReducer from './submission.store';
import classReducer from './class.store';
import instructorReducer from './instructor.store';
import assignmentReducer from './assignment.store';
import coursesReducer from './courses.store';
import reviewReducer from './review.store';

const reducers = combineReducers({
  auth: authReducer,
  breadcrumb: breadcrumbReducer,
  user: userReducer,
  submission: submissionReducer,
  class: classReducer,
  instructor: instructorReducer,
  assignment: assignmentReducer,
  courses: coursesReducer,
  review: reviewReducer
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage
};

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    state = undefined;
    http.client.defaults.headers.common['Authorization'] = '';
  }
  return reducers(state, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export default store;
