import React, { Fragment, useState, useEffect } from 'react';
import { Autocomplete, Button, LinearProgress, Modal, TextField } from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import FileDropzone from '../FileDropzone';
import { swalConfirmation, swalSuccess } from '../../utils';
import { toast } from 'react-toastify';
import Toast from '../Toast';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearSubmissionState,
  getSubmissions,
  manualCreateSubmission
} from '../../store/submission.store';
import { useParams } from 'react-router';
import { classService } from '../../api/services';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 0.4,
  bgcolor: 'white',
  boxShadow: 24,
  p: 4
};

const ManualCreateSubmissionModal = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { courseId, classId, assignmentId } = params;
  const data = {
    courseId,
    classId,
    assignmentId,
    formData: null
  };
  const { isSuccess, isError, errorMessage } = useSelector((state) => state.submission);
  const [file, setFile] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [students, setStudents] = useState([]);
  const [form, setForm] = useState({ name: '' });

  /**
   * Close modal only when user clicks on the 'x' button.
   *
   * Resets local states before closing.
   *
   * @param {*} _ - unused parameter
   * @param {*} reason - reason for closing modal
   */
  const handleModalClose = async (_, reason) => {
    if (reason !== 'backdropClick') {
      const { isConfirmed } = await swalConfirmation(
        'Closing this modal will discard all changes. Are you sure?'
      );
      if (isConfirmed) {
        resetStates();
        handleClose();
      }
    }
  };

  /**
   * Handle form input change.
   *
   * Set local state with new value.
   */
  const handleFormInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  /**
   * Handle submission creation.
   *
   * 1. Validate form inputs.
   * 2. Show confirmation alert.
   * 3. Append data to form data.
   * 4. Send form data to server.
   */
  const handleCreate = async () => {
    if (!validateForm()) return;
    const { isConfirmed } = await swalConfirmation(
      'Are you sure you want to create this submission?'
    );
    if (!isConfirmed) return;
    setDisableButton(true);
    setShowProgress(true);

    const formData = new FormData();
    formData.append('name', form.name);
    formData.append('submittedDate', form.submittedDate);
    formData.append('file', file);
    data.formData = formData;
    dispatch(clearSubmissionState());
    dispatch(manualCreateSubmission(data));
  };

  /**
   * Validate form data.
   *
   * @returns {boolean} true if form is valid, false otherwise
   */
  const validateForm = () => {
    if (!form.name || !file) {
      toast.error('Please fill in all fields.');
      return false;
    }
    return true;
  };

  const handleStudentChange = (value) => {
    setForm({ ...form, name: value.name });
  };

  const getStudents = async () => {
    try {
      const res = await classService.getClassStudents(classId);
      const studentList = res.data.result;
      setStudents(studentList);
    } catch (error) {
      console.error(error);
      toast.error('Error getting students');
    }
  };

  useEffect(() => {
    if (open) {
      getStudents();
    }
  }, [open]);

  /**
   * Handle isSuccess or isError state change.
   *
   * isSuccess:
   *  1. Show success alert.
   *  2. Dispatch action to clear submission state.
   *  3. Dispatch action to get submissions.
   *  4. Close modal.
   *
   * isError:
   *  1. Show error toast.
   *  2. Dispatch action to clear submission state.
   *  3. Enable button and hide progress.
   */
  useEffect(() => {
    if (!open) return;

    if (isSuccess) {
      setShowProgress(false);
      swalSuccess('Submission created successfully').then(() => {
        resetStates();
        dispatch(clearSubmissionState());
        dispatch(getSubmissions(data));
        handleClose();
      });
    } else if (isError) {
      toast.error(errorMessage ?? 'Error creating submission');
      dispatch(clearSubmissionState());
      setDisableButton(false);
      setShowProgress(false);
    }
  }, [isSuccess, isError]);

  const showErrorMsg = () => {
    toast.error('File type not supported');
  };

  /**
   * Reset local states to default.
   */
  const resetStates = () => {
    setFile(null);
    setDisableButton(false);
    setShowProgress(false);
  };

  return (
    <Fragment>
      <Modal open={open} disableEscapeKeyDown>
        <Box sx={style}>
          <CloseIcon
            className="absolute right-0 top-0 m-2 cursor-pointer transition ease-in-out delay-130 hover:scale-110 duration-300"
            onClick={handleModalClose}
          />
          <div className="flex flex-col space-y-6 text-center">
            <div>
              <h1>Manually Create Submission</h1>
              <p className="text-gray-500">Enter submission details and upload file to create</p>
              <p className="text-gray-500 italic">
                only &quot;.doc/.docx/.pdf&quot; files are accepted
              </p>
            </div>
            <div className="grid grid-cols-3 gap-4 items-center">
              <div>Student Name</div>
              <div className="col-span-2">
                {students.length && (
                  <Autocomplete
                    className="w-full bg-white"
                    options={students}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    filterSelectedOptions
                    onChange={(e, value) => handleStudentChange(value)}
                    renderInput={(params) => <TextField {...params} placeholder="Student" />}
                  />
                )}
              </div>
            </div>
            <FileDropzone
              accept={{
                'application/msword': ['.doc'],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
                  '.docx'
                ],
                'application/pdf': ['.pdf']
              }}
              maxFiles={1}
              file={file}
              handleDropAccepted={(files) => setFile(files[0])}
              handleDropRejected={showErrorMsg}
            />
            {showProgress && <LinearProgress />}
            <Button
              className="btn btn-primary"
              variant="contained"
              disabled={disableButton}
              onClick={handleCreate}>
              Import Submission
            </Button>
          </div>
        </Box>
      </Modal>
      <Toast />
    </Fragment>
  );
};

export default ManualCreateSubmissionModal;
