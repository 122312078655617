import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import DataTable from '../../../components/DataTable';
import { clearReviewState, getReview } from '../../../store/review.store';
import TableLink from '../../TableLink';
import { pushBreadcrumb } from '../../../store/breadcrumb.store';
import Breadcrumb from '../../../components/Breadcrumb';

const ReviewedByOthers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const params = useParams();
  const { courseId, assignmentId } = params;
  const { user } = useSelector((state) => state.user);
  const data = {
    courseId,
    assignmentId: assignmentId,
    accountId: user.id
  };
  const { reviews, isFetching } = useSelector((state) => state.review);
  const [filteredReviews, setFilteredReviews] = useState([]);

  const columns = [
    {
      name: 'Reviewed By',
      selector: (row) => row.studentName,
      sortable: true,
      width: '35%'
    },
    {
      name: 'Review Date',
      selector: (row) => row.reviewDate,
      sortable: false,
      width: '25%'
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: false,
      width: '25%'
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div key={row.reviewId}>
          <TableLink
            className="text-primary p-1"
            href={`${location.pathname.replace('/peers', '/')}${row.reviewId}/peers`}
            onClick={(e) => handleViewReviewClick(e, row.reviewId)}
            disabled={row.reviewDate === '-'}>
            View Feedback
          </TableLink>
        </div>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '15%'
    }
  ];

  useEffect(() => {
    dispatch(clearReviewState());
    dispatch(getReview(data));
  }, []);

  useEffect(() => {
    setFilteredReviews(reviews);
  }, [reviews]);

  const handleFilter = (e) => {
    const { value } = e.target;
    const filteredReviews = reviews.filter((review) =>
      review.studentName.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredReviews(filteredReviews);
  };

  const handleViewReviewClick = (e, id) => {
    e.preventDefault();
    var foundReview = reviews.find((item) => item.reviewId === id);
    if (!foundReview) return;

    const href = e.target.attributes.href.value;
    const breadcrumbObj = {
      label: foundReview.studentName,
      href: location.pathname + '/' + href
    };
    dispatch(pushBreadcrumb(breadcrumbObj));
    navigate(href);
  };

  return (
    <div>
      <div className="flex justify-between mb-2">
        <div className="flex flex-col">
          <h1>Reviewed By Peers</h1>
          <Breadcrumb />
        </div>
        <div>
          <input
            type="text"
            placeholder="search reviews..."
            className="input input-bordered mt-4"
            onChange={handleFilter}
          />
        </div>
      </div>
      <div>
        <DataTable data={filteredReviews} columns={columns} loading={isFetching} />
      </div>
    </div>
  );
};

export default ReviewedByOthers;
