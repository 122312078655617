import React, { Fragment, useState, useEffect } from 'react';
import { Autocomplete, Button, MenuItem, Select, TextField } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import NavSteps from '../../../components/NavSteps';
import { useDispatch, useSelector } from 'react-redux';
import { swalConfirmation, swalSuccess } from '../../../utils';
import { toast } from 'react-toastify';
import Toast from '../../../components/Toast';
import { createCourses, getUserCourses, clearCourseState } from '../../../store/courses.store';
import { useNavigate } from 'react-router-dom';
import CreateInstructorModal from '../../../components/Course/CreateInstructorModal';
import { userService } from '../../../api/services';

const CreateCourse = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSuccess, isError, errorMessage } = useSelector((state) => state.courses);
  const { user } = useSelector((state) => state.user);

  const [disableButton, setDisableButton] = useState(false);
  const [createCoursePage, setCreateCoursePage] = useState(false);
  const [instructors, setInstructors] = useState([]);
  const [form, setForm] = useState({ name: '', instructors: [], startDate: '', endDate: '' });
  const [openModal, setOpenModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const steps = [
    {
      title: 'Create Course',
      icon: <HomeIcon />
    }
  ];

  /**
   * Handle form input change.
   *
   * Set local state with new value.
   */
  const handleFormInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleInstructorChange = (value) => {
    const instructorIds = value.map((instructor) => instructor.id);
    setForm({ ...form, instructors: instructorIds });
  };

  /**
   * Handle course creation.
   *
   * 1. Validate form inputs.
   * 2. Show confirmation alert.
   * 3. Append data to form data.
   * 4. Send form data to server.
   */
  const handleCreate = async () => {
    setCreateCoursePage(true);
    if (!validateForm()) return;
    const { isConfirmed } = await swalConfirmation('Are you sure you want to create this course?');
    if (!isConfirmed) return;
    setDisableButton(true);
    dispatch(clearCourseState());
    dispatch(createCourses({ accountId: user.id, data: form }));
  };

  /**
   * Validate form data.
   *
   * @returns {boolean} true if form is valid, false otherwise
   */
  const validateForm = () => {
    if (!form.name || !form.startDate || !form.endDate) {
      toast.error('Please fill in all fields.');
      return false;
    }
    return true;
  };

  /**
   * Handle isSuccess or isError state change.
   *
   * isSuccess:
   *  1. Show success alert.
   *  2. Dispatch action to clear course state.
   *  3. Dispatch action to get courses.
   *  4. Close modal.
   *
   * isError:
   *  1. Show error toast.
   *  2. Dispatch action to clear course state.
   *  3. Enable button.
   */
  useEffect(() => {
    if (!createCoursePage) return;
    if (isSuccess) {
      swalSuccess('Course created successfully').then(() => {
        resetStates();
        dispatch(clearCourseState());
        dispatch(getUserCourses({ accountId: user.id }));
        navigate('/instructor/courses', { replace: true });
      });
    } else if (isError) {
      toast.error(errorMessage ?? 'Error creating course');
      dispatch(clearCourseState());
      setDisableButton(false);
      setCreateCoursePage(false);
    }
  }, [isSuccess, isError]);

  /**
   * Reset local states to default.
   */
  const resetStates = () => {
    setDisableButton(false);
  };

  const getInstructors = async () => {
    try {
      const res = await userService.getInstructors();
      const instructorRes = res.data.result;
      if (instructorRes.length) {
        // sort by current user first
        const currentUser = instructorRes.find((instructor) => instructor.id === user.id);
        if (currentUser) {
          instructorRes.splice(instructorRes.indexOf(currentUser), 1);
          instructorRes.unshift(currentUser);
        }
        const cleanedInstructors = instructorRes.map((instructor) => ({
          id: instructor.id,
          name: instructor.name
        }));
        setInstructors(cleanedInstructors);
      }
    } catch (error) {
      console.error(error);
      toast.error('Error getting instructors');
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    getInstructors();
  };

  useEffect(() => {
    getInstructors();
  }, []);

  return (
    <Fragment>
      <NavSteps steps={steps} currentStep={currentStep} setCurrentStep={setCurrentStep} />
      <div className="flex flex-col space-y-6 text-center w-3/4 mx-auto">
        <div className="grid grid-cols-3 gap-4 items-center mt-4">
          <div>Course Name</div>
          <div className="col-span-2">
            <input
              type="text"
              className="input input-bordered w-full"
              name="name"
              placeholder="Enter Course Name"
              onChange={handleFormInputChange}
            />
          </div>
          <div>Course Instructor(s)</div>
          <div className="col-span-2 flex space-x-2">
            {instructors.length && (
              <Autocomplete
                multiple
                id="instructors"
                className="w-full bg-white"
                options={instructors}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                filterSelectedOptions
                onChange={(e, value) => handleInstructorChange(value)}
                renderInput={(params) => <TextField {...params} placeholder="Instructors" />}
              />
            )}
            <Button className="btn btn-warning h-14" onClick={() => setOpenModal(true)}>
              Create Instructor
            </Button>
          </div>
          <div>Start Date</div>
          <div className="col-span-2">
            <input
              type="datetime-local"
              className="input input-bordered w-full"
              name="startDate"
              onChange={handleFormInputChange}
            />
          </div>
          <div>End Date</div>
          <div className="col-span-2">
            <input
              type="datetime-local"
              className="input input-bordered w-full"
              name="endDate"
              onChange={handleFormInputChange}
            />
          </div>
        </div>
        <div className="flex items-center justify-between">
          <Button className="back-btn" variant="outlined" onClick={() => navigate(-1)}>
            Back
          </Button>
          <Button
            className="btn btn-primary"
            variant="contained"
            disabled={disableButton}
            onClick={handleCreate}>
            Save & Finish
          </Button>
        </div>
      </div>
      <CreateInstructorModal open={openModal} handleClose={handleCloseModal} />
      <Toast />
    </Fragment>
  );
};

export default CreateCourse;
