import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import ViewFreeResponseReview from '../../../components/Review/ViewFreeResponseReview';
import ViewScaleResponseReview from '../../../components/Review/ViewScaleResponseReview';
import Toast from '../../../components/Toast';
import { QuestionType } from '../../../constants';
import { reviewService } from '../../../api/services/';
import Breadcrumb from '../../../components/Breadcrumb';
import { useDispatch } from 'react-redux';
import { LinearProgress } from '@mui/material';
import { DocumentViewer } from 'react-documents';

const ViewPeerReview = () => {
  const params = useParams();
  const { courseId, assignmentId, reviewId } = params;
  const data = {
    courseId,
    assignmentId: assignmentId,
    reviewId: reviewId
  };

  const initialReviewState = {
    assignmentName: '',
    fileUrl: '',
    question: [],
    studentName: ''
  };

  const [review, setReview] = useState(initialReviewState);
  const [showProgress, setShowProgress] = useState(true);

  const getPeerFeedback = async () => {
    try {
      const res = await reviewService.getPeerReview(data);
      const reviewResult = res.data?.result[0];
      if (reviewResult) {
        setReview(reviewResult);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPeerFeedback();
  }, []);

  return (
    <div className="pb-10">
      <h1>{review?.assignmentName}</h1>
      <Breadcrumb />
      <div className="flex justify-between my-4 space-x-10">
        <div className="w-full">
          {showProgress && <LinearProgress />}
          <DocumentViewer
            className="w-full drop-shadow-md"
            viewer="office"
            url={review?.fileUrl}
            style={{ height: '80vh' }}
            loaded={() => setShowProgress(false)}
          />
        </div>
        <div
          className="w-1/2 bg-gray-100 p-4 overflow-y-auto scrollbar"
          style={{ maxHeight: '80vh' }}>
          {review?.question?.map((question) =>
            question.qnTypeId === QuestionType.FREE_RESPONSE ? (
              <ViewFreeResponseReview data={question} />
            ) : (
              <ViewScaleResponseReview data={question} />
            )
          )}
        </div>
      </div>
      <Toast />
    </div>
  );
};

export default ViewPeerReview;
