import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { classService } from '../api/services';

export const getClassEdit = createAsyncThunk(
  'classes/getClassEdit',
  async (data, { rejectWithValue }) => {
    try {
      const response = await classService.getClassEdit(data);
      const instructors = response.data.result;
      return instructors;
    } catch (error) {
      return rejectWithValue(error.responseException.exceptionMessage);
    }
  }
);

export const getInstructor = createAsyncThunk(
  'instructor/get',
  async (courseId, { rejectWithValue }) => {
    try {
      const response = await classService.getInstructor(courseId);
      const instructor = response.data.result;
      return instructor;
    } catch (error) {
      return rejectWithValue(error.responseException.exceptionMessage);
    }
  }
);

const initialState = {
  instructors: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: ''
};

export const instructorSlice = createSlice({
  name: 'instructor',
  initialState,
  reducers: {
    clearInstructorState: (state) => {
      state.isSuccess = false;
      state.isFetching = false;
      state.isError = false;
    }
  },
  extraReducers: {
    [getClassEdit.fulfilled]: (state, action) => {
      state.instructors = action.payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getClassEdit.pending]: (state) => {
      state.isFetching = true;
    },
    [getClassEdit.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },

    [getInstructor.fulfilled]: (state, action) => {
      state.instructors = action.payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getInstructor.pending]: (state) => {
      state.isFetching = true;
    },
    [getInstructor.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    }
  }
});

export const { clearInstructorState } = instructorSlice.actions;
export default instructorSlice.reducer;
