import React, { Fragment, useEffect, useState } from 'react';
import { Button, LinearProgress, Link, Modal } from '@mui/material';
import { Box } from '@mui/system';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import Toast from '../Toast';
import { formatDatetime, swalConfirmation, swalError, swalSuccess } from '../../utils';
import { submissionService } from '../../api/services';
import DataTable from '../DataTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearSubmissionState,
  createSubmissions,
  getSubmissions
} from '../../store/submission.store';
import FileDropzone from '../FileDropzone';
import { useParams } from 'react-router';
import { deleteImportedSubmissions } from '../../api/services/submission.service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 0.5,
  bgcolor: 'white',
  boxShadow: 24,
  p: 4
};

const ImportSubmissionModal = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { courseId, classId, assignmentId } = params;
  const data = {
    courseId,
    classId,
    assignmentId,
    submissions: []
  };
  const { isSuccess, isError } = useSelector((state) => state.submission);
  const [file, setFile] = useState(null);
  const [submissions, setSubmissions] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [showProgress, setShowProgress] = useState(false);

  /**
   * Close modal only when user clicks on the 'x' button.
   *
   * Resets local states before closing.
   *
   * @param {*} _ - unused parameter
   * @param {*} reason - reason for closing modal
   */
  const handleModalClose = async (_, reason) => {
    if (reason !== 'backdropClick') {
      const { isConfirmed } = await swalConfirmation(
        'Closing this modal will discard all changes. Are you sure?'
      );
      if (!isConfirmed) return;
      if (submissions?.length) {
        deleteImportedSubmissions(submissions);
      }
      resetStates();
      handleClose();
    }
  };

  /**
   * Handle import submission button click.
   *
   * 1. Validate file.
   * 2. Show confirmation dialog.
   * 3. Disable button and show progress.
   * 4. Append file to form data and send to server.
   * 5. Enable button and hide progress.
   */
  const handleImport = async () => {
    if (!file) {
      toast.error('Please select a file');
      return;
    }
    const { isConfirmed } = await swalConfirmation('Are you sure you want to import this file?');
    if (!isConfirmed) return;

    try {
      setDisableButton(true);
      setShowProgress(true);

      const formData = new FormData();
      formData.append('file', file);
      const response = await submissionService.importSubmissions(formData);
      const submissions = response.data.result;

      swalSuccess(`Successfully imported ${submissions.length} submissions`);
      setSubmissions(submissions);
      setDisableButton(false);
      setShowProgress(false);
    } catch (error) {
      console.error(error);
      swalError('Error importing file');
      setShowProgress(false);
      setDisableButton(false);
    }
  };

  /**
   * Handle create submissions.
   *
   * 1. Show confirmation dialog.
   * 2. Disable button and show progress.
   * 3. Clear submission state.
   * 4. Dispatch create submissions action.
   */
  const handleCreate = async () => {
    const { isConfirmed } = await swalConfirmation(
      'Are you sure you want to create these submissions?'
    );
    if (!isConfirmed) return;
    setDisableButton(true);
    setShowProgress(true);
    dispatch(clearSubmissionState());
    data.submissions = submissions;
    dispatch(createSubmissions(data));
  };

  /**
   * Clear submission state when component loads.
   */
  useEffect(() => {
    dispatch(clearSubmissionState());
  }, []);

  /**
   * Handle isSuccess or isError state change.
   *
   * isSuccess:
   *  1. Show success alert.
   *  2. Dispatch action to clear submission state.
   *  3. Dispatch action to get submissions.
   *  4. Close modal.
   *
   * isError:
   *  1. Show error toast.
   *  2. Dispatch action to clear submission state.
   *  3. Enable button and hide progress.
   */
  useEffect(() => {
    if (!open) return;

    if (isSuccess) {
      setShowProgress(false);
      swalSuccess('Submissions created successfully').then(() => {
        resetStates();
        dispatch(clearSubmissionState());
        dispatch(getSubmissions(data));
        handleClose();
      });
    } else if (isError) {
      toast.error('Error creating submissions');
      dispatch(clearSubmissionState());
      setDisableButton(false);
      setShowProgress(false);
    }
  }, [isSuccess, isError]);

  const showErrorMsg = () => {
    toast.error('File type not supported');
  };

  /**
   * Reset local states to default.
   */
  const resetStates = () => {
    setFile(null);
    setSubmissions(null);
    setDisableButton(false);
    setShowProgress(false);
  };

  /**
   * Set columns and data for data table.
   *
   * @returns {DataTable}
   */
  const showDatatable = () => {
    const columns = [
      {
        name: 'Student Name',
        selector: (row) => row.name,
        sortable: true
      },
      {
        name: 'File Name',
        cell: (row) => (
          <Link href={row.fileUrl} target="_blank">
            {row.fileName}
          </Link>
        ),
        selector: (row) => row.fileName,
        sortable: true
      },
      {
        name: 'Submission Date',
        selector: (row) => formatDatetime(row.submittedDate),
        sortable: true
      }
    ];

    return <DataTable data={submissions} columns={columns} />;
  };

  return (
    <Fragment>
      <Modal open={open} disableEscapeKeyDown>
        <Box sx={style}>
          <CloseIcon
            className="absolute right-0 top-0 m-2 cursor-pointer transition ease-in-out delay-130 hover:scale-110 duration-300"
            onClick={handleModalClose}
          />
          <div className="flex flex-col space-y-6 text-center">
            <div>
              <h1>Import Submissions</h1>
              <p className="text-gray-500">Upload your zip file to import submissions</p>
              <p className="text-gray-500 italic">only &quot;.zip&quot; files are accepted</p>
            </div>
            {submissions ? (
              showDatatable()
            ) : (
              <FileDropzone
                accept={{ 'application/zip': ['.zip'] }}
                maxFiles={1}
                file={file}
                handleDropAccepted={(files) => setFile(files[0])}
                handleDropRejected={showErrorMsg}
              />
            )}
            {showProgress && <LinearProgress />}
            <Button
              className="btn btn-primary"
              variant="contained"
              disabled={disableButton}
              onClick={submissions ? handleCreate : handleImport}>
              Import Submissions
            </Button>
          </div>
        </Box>
      </Modal>
      <Toast />
    </Fragment>
  );
};

export default ImportSubmissionModal;
