import React, { useState } from 'react';
import { Link } from '@mui/material';
import StatsCard from '../../components/StatsCard';
import HomeIcon from '@mui/icons-material/Home';
import RateReviewIcon from '@mui/icons-material/RateReview';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { getStudentStats } from '../../api/services/stats.service';
import { useEffect } from 'react';

const StudentStats = () => {
  const initialStats = [
    {
      title: 'Courses',
      description: 'No. of courses',
      stats: 0,
      icon: <HomeIcon />
    },
    {
      title: 'Assignments',
      description: 'No. of assignments',
      stats: 0,
      icon: <AssignmentIcon />
    },
    {
      title: 'To review',
      description: 'No. of submissions to review',
      stats: 0,
      icon: <RateReviewIcon />
    }
  ];

  const [data, setData] = useState({ loading: true, stats: initialStats });

  const getStats = async () => {
    try {
      const res = await getStudentStats();
      const { result } = res.data;
      const newStats = [...data.stats];
      newStats[0].stats = result.courses;
      newStats[1].stats = result.assignments;
      newStats[2].stats = result.toReview;
      setData({ loading: false, stats: newStats });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <div
      className={`stats shadow mt-4 bg-primary text-white ${data.loading ? 'animate-pulse' : ''}`}>
      {data.stats.map((item) => (
        <StatsCard key={item} data={item} />
      ))}
    </div>
  );
};

export default StudentStats;
