import React from 'react';
import { Button, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const InstructorDetail = ({ data, handleInputChange, handleDelete }) => {
  const { id, name, email } = data;

  return (
    <div className="flex justify-center space-x-4">
      <input
        type="text"
        className="input input-bordered w-full"
        placeholder="Staff ID"
        value={id}
        name="id"
        maxLength={10}
        onChange={handleInputChange}
      />
      <input
        type="text"
        className="input input-bordered w-full"
        placeholder="Name"
        variant="outlined"
        value={name}
        name="name"
        onChange={handleInputChange}
      />
      <input
        type="text"
        className="input input-bordered w-full"
        placeholder="Email"
        variant="outlined"
        value={email}
        name="email"
        onChange={handleInputChange}
      />
      <div className="flex flex-col justify-center">
        <CloseIcon
          className="text-red-500 cursor-pointer transition ease-in-out delay-130 hover:scale-110 duration-300"
          onClick={handleDelete}
        />
      </div>
    </div>
  );
};

export default InstructorDetail;
