import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { userService } from '../api/services';
import { logout } from './auth.store';

export const getUser = createAsyncThunk('users/me', async (data, thunkApi) => {
  try {
    const response = await userService.getMe();
    const user = response.data.result;
    return user;
  } catch (error) {
    thunkApi.dispatch(logout());
    return thunkApi.rejectWithValue(error.responseException.exceptionMessage);
  }
});

const initialState = {
  user: {},
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: ''
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    [getUser.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    }
  }
});

export default userSlice.reducer;
