import { Button } from '@mui/material';
import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { clearBreadcrumbs, pushBreadcrumb } from '../../../store/breadcrumb.store';
import { getUserCourses, deleteCourses, clearCourseState } from '../../../store/courses.store';
import DataTable from '../../../components/DataTable';
import UpdateCourseModal from '../../../components/Course/UpdateCourseModal';
import { swalConfirmation, swalSuccess } from '../../../utils';
import { toast } from 'react-toastify';
import Toast from '../../../components/Toast';
import moment from 'moment';
import { formatDatetime } from '../../../utils';
import TableLink from '../../TableLink';
import InstructorStats from '../../../components/Stats/InstructorStats';

const Courses = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isFetching, isError, isSuccess, courses, errorMessage } = useSelector(
    (state) => state.courses
  );
  const { user } = useSelector((state) => state.user);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [openUpdateCourseModal, setOpenUpdateCourseModal] = useState(false);
  const [openDeleteCourseModal, setOpenDeleteCourseModal] = useState(false);

  const columns = [
    {
      name: 'Course',
      selector: (row) => row.name,
      sortable: true,
      width: '20%'
    },
    {
      name: 'Status',
      selector: (row) => getStatus(row.endDate),
      cell: (row) => {
        const status = getStatus(row.endDate);
        const color = status === 'Active' ? 'green' : 'red';
        return <p style={{ color: color, display: 'inline' }}>{status}</p>;
      },
      sortable: true,
      width: '10%'
    },
    {
      name: 'Start Date',
      selector: (row) => formatDatetime(row.startDate),
      sortable: true,
      width: '15%'
    },
    {
      name: 'End Date',
      selector: (row) => formatDatetime(row.endDate),
      sortable: true,
      width: '15%'
    },
    {
      name: 'Assignments',
      cell: (row) => (
        <TableLink href={`${row.id}/assignments`} onClick={(e) => handleLinkClick(e, row.id)}>
          View Assignments
        </TableLink>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '10%'
    },
    {
      name: 'Class List',
      cell: (row) => (
        <TableLink href={`${row.id}/classes`} onClick={(e) => handleLinkClick(e, row.id)}>
          View Class
        </TableLink>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '10%'
    },
    {
      name: 'Actions',
      cell: (row) => [
        <div key={row.id}>
          <TableLink
            className="text-warning p-1"
            onClick={() => [selectedRow(row), setOpenUpdateCourseModal(true)]}>
            Edit
          </TableLink>
          |
          <TableLink className="p-1 text-red-500" onClick={() => handleDelete(row.id)}>
            Delete
          </TableLink>
        </div>
      ],
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '20%'
    }
  ];

  useEffect(() => {
    dispatch(getUserCourses({ accountId: user.id }));
    dispatch(clearBreadcrumbs());
    const breadcrumbObj = {
      label: 'Course List',
      href: '/instructor/courses'
    };
    dispatch(pushBreadcrumb(breadcrumbObj));
  }, []);

  useEffect(() => {
    setFilteredCourses(courses);
  }, [courses]);

  /**
   * Handle isSuccess or isError state change.
   *
   * isSuccess:
   *  1. Show success alert.
   *  2. Dispatch action to clear course state.
   *  3. Dispatch action to get courses.
   *  4. Close modal.
   *
   * isError:
   *  1. Show error toast.
   *  2. Dispatch action to clear course state.
   *  3. Enable button.
   */
  useEffect(() => {
    if (!openDeleteCourseModal) return;
    if (isSuccess) {
      swalSuccess('Course deleted successfully').then(() => {
        dispatch(clearCourseState());
        setOpenDeleteCourseModal(false);
        dispatch(getUserCourses({ accountId: user.id }));
      });
    } else if (isError) {
      toast.error(errorMessage ?? 'Error deleting course');
      dispatch(clearCourseState());
      setOpenDeleteCourseModal(false);
    }
  }, [isSuccess, isError]);

  const handleFilter = (e) => {
    const { value } = e.target;
    const filteredCourses = courses.filter((course) =>
      course.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredCourses(filteredCourses);
  };

  const handleDelete = async (id) => {
    setOpenDeleteCourseModal(true);
    const { isConfirmed } = await swalConfirmation('Are you sure you want to delete this course?');
    if (!isConfirmed) return;
    dispatch(clearCourseState());
    dispatch(deleteCourses({ courseId: id }));
  };

  const handleLinkClick = (e, courseId) => {
    e.preventDefault();

    var course = courses.find((course) => course.id === courseId);
    if (!course) return;

    const href = e.target.attributes.href.value;
    const breadcrumbObj = {
      label: course.name,
      href: location.pathname + '/' + href
    };
    dispatch(pushBreadcrumb(breadcrumbObj));
    navigate(href);
  };

  const selectedRow = (data) => {
    const selectedCourse = data;
    setSelectedCourse(selectedCourse);
  };

  const getStatus = (endDate) => (moment().diff(endDate) > 0 ? 'Expired' : 'Active');

  return (
    <Fragment>
      <div className="flex justify-center my-4">
        <InstructorStats />
      </div>
      <div className="flex justify-between mb-2 ">
        <h1>Course List</h1>
      </div>
      <div className="flex justify-between mb-2 ">
        <Button
          className="btn btn-primary"
          variant="contained"
          href={`${location.pathname}/create`}>
          Create Course
        </Button>

        <div className="flex space-x-2 justify-end">
          <input
            type="text"
            placeholder="search course..."
            className="input input-bordered"
            onChange={handleFilter}
          />
        </div>
      </div>
      {<DataTable data={filteredCourses} columns={columns} loading={isFetching} />}
      <UpdateCourseModal
        open={openUpdateCourseModal}
        handleClose={() => setOpenUpdateCourseModal(false)}
        row={selectedCourse}
      />
      <Toast />
    </Fragment>
  );
};

export default Courses;
