import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { classService } from '../api/services';
import { updateClass } from '../api/services/class.service';

export const getClass = createAsyncThunk('classes/get', async (data, { rejectWithValue }) => {
  try {
    const response = await classService.getClass(data);
    const classes = response.data.result;
    return classes;
  } catch (error) {
    return rejectWithValue(error.responseException.exceptionMessage);
  }
});

export const getCourseName = createAsyncThunk(
  'course/get',
  async (courseId, { rejectWithValue }) => {
    try {
      const response = await classService.getCourseName(courseId);
      const courseName = response.data.message;
      return courseName;
    } catch (error) {
      return rejectWithValue(error.responseException.exceptionMessage);
    }
  }
);

export const getClassEdit = createAsyncThunk(
  'classes/getClassEdit',
  async (data, { rejectWithValue }) => {
    try {
      const response = await classService.getClassEdit(data);
      const classes = response.data.result;
      return classes;
    } catch (error) {
      return rejectWithValue(error.responseException.exceptionMessage);
    }
  }
);

export const updateClasses = createAsyncThunk('courses/update', async (data, thunkApi) => {
  try {
    const response = await classService.updateClass(data);
    const class1 = response.data.result;
    return class1;
  } catch (error) {
    return thunkApi.rejectWithValue(error.responseException.exceptionMessage);
  }
});

export const deleteClass = createAsyncThunk('class/delete', async (data, { rejectWithValue }) => {
  try {
    const response = await classService.deleteClass(data);
    const classes = response.data.result;
    return classes;
  } catch (error) {
    return rejectWithValue(error.responseException.exceptionMessage);
  }
});

export const createClass = createAsyncThunk('class/create', async (data, { rejectWithValue }) => {
  try {
    const response = await classService.createClass(data);
    const classes = response.data.result;
    return classes;
  } catch (error) {
    return rejectWithValue(error.responseException.exceptionMessage);
  }
});

const initialState = {
  classes: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: ''
};

export const classSlice = createSlice({
  name: 'class',
  initialState,
  reducers: {
    clearClassState: (state) => {
      state.isSuccess = false;
      state.isFetching = false;
      state.isError = false;
    }
  },
  extraReducers: {
    [getClass.fulfilled]: (state, action) => {
      state.classes = action.payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getClass.pending]: (state) => {
      state.isFetching = true;
    },
    [getClass.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [getCourseName.fulfilled]: (state, action) => {
      state.classes = action.payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getCourseName.pending]: (state) => {
      state.isFetching = true;
    },
    [getCourseName.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [getClassEdit.fulfilled]: (state, action) => {
      state.classes = action.payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getClassEdit.pending]: (state) => {
      state.isFetching = true;
    },
    [getClassEdit.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [updateClasses.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [updateClasses.pending]: (state) => {
      state.isFetching = true;
    },
    [updateClasses.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [deleteClass.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [deleteClass.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteClass.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [createClass.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [createClass.pending]: (state) => {
      state.isFetching = true;
    },
    [createClass.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    }
  }
});

export const { clearClassState } = classSlice.actions;
export default classSlice.reducer;
