import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import store from './store';
import { BrowserRouter } from 'react-router-dom';

import './styles/index.css';
import { Provider } from 'react-redux';
import InjectTailwind from './InjectTailwind';
import { PersistGate } from 'redux-persist/integration/react';
import persistStore from 'redux-persist/es/persistStore';

// eslint-disable-next-line no-undef
const root = ReactDOM.createRoot(document.getElementById('root'));
let persister = persistStore(store);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persister}>
          <InjectTailwind>
            <App />
          </InjectTailwind>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
