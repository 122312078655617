import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { courseService } from '../api/services';
import { classService } from '../api/services';

export const getUserCourses = createAsyncThunk(
  'courses/getUserCourses',
  async ({ accountId }, thunkApi) => {
    try {
      const response = await courseService.getUserCourses(accountId);
      const courses = response.data.result;
      return courses;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const createCourses = createAsyncThunk(
  'courses/create',
  async ({ data, accountId }, thunkApi) => {
    try {
      const response = await courseService.createCourses(data, accountId);
      const courses = response.data.result;
      return courses;
    } catch (error) {
      return thunkApi.rejectWithValue(error.responseException.exceptionMessage);
    }
  }
);

export const updateCourses = createAsyncThunk(
  'courses/update',
  async ({ data, courseId }, thunkApi) => {
    try {
      const response = await courseService.updateCourses(data, courseId);
      const courses = response.data.result;
      return courses;
    } catch (error) {
      return thunkApi.rejectWithValue(error.responseException.exceptionMessage);
    }
  }
);

export const deleteCourses = createAsyncThunk('courses/delete', async ({ courseId }, thunkApi) => {
  try {
    const response = await courseService.deleteCourses(courseId);
    const courses = response.data.result;
    return courses;
  } catch (error) {
    return thunkApi.rejectWithValue(error.responseException.exceptionMessage);
  }
});

//Hong Ying
export const getCourseName = createAsyncThunk(
  'course/get',
  async (courseId, { rejectWithValue }) => {
    try {
      const response = await classService.getCourseName(courseId);
      const courseName = response.data.message;
      return courseName;
    } catch (error) {
      return rejectWithValue(error.responseException.exceptionMessage);
    }
  }
);

const initialState = {
  courses: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: ''
};

export const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    clearCourseState: (state) => {
      state.isSuccess = false;
      state.isFetching = false;
      state.isError = false;
    }
  },
  extraReducers: {
    [getUserCourses.fulfilled]: (state, action) => {
      state.courses = action.payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getUserCourses.pending]: (state, action) => {
      state.isFetching = true;
    },
    [getUserCourses.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [createCourses.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [createCourses.pending]: (state) => {
      state.isFetching = true;
    },
    [createCourses.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [updateCourses.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [updateCourses.pending]: (state) => {
      state.isFetching = true;
    },
    [updateCourses.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [deleteCourses.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [deleteCourses.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteCourses.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [getCourseName.fulfilled]: (state, action) => {
      state.courses = action.payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getCourseName.pending]: (state) => {
      state.isFetching = true;
    },
    [getCourseName.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    }
  }
});

export const { clearCourseState } = coursesSlice.actions;
export default coursesSlice.reducer;
