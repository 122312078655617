import React from 'react';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { pushBreadcrumb } from '../../../store/breadcrumb.store';
import Breadcrumb from '../../../components/Breadcrumb';
import DataTable from '../../../components/DataTable';
import { clearClassState, getClass, deleteClass } from '../../../store/class.store';
import { swalConfirmation, swalSuccess } from '../../../utils';
import TableLink from '../../TableLink';
import UpdateClassModal from '../../../components/Class/UpdateClassModal';
import { clearCourseState } from '../../../store/courses.store';
import { classService } from '../../../api/services';
import { toast } from 'react-toastify';

const Classes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const params = useParams();
  const { courseId } = params;
  const accountId = user.id;
  const data = {
    courseId,
    accountId: accountId
  };
  console.log(accountId);
  console.log(courseId);
  const { classes, isFetching, isSuccess, isError } = useSelector((state) => state.class);
  const [filteredClass, setFilteredClass] = useState([]);
  const [selectedClass, setSelectedClass] = useState([]);
  const [openUpdateClassModal, setOpenUpdateClassModal] = useState(false);

  const columns = [
    {
      name: 'Class ID',
      selector: (row) => row.classId,
      sortable: true,
      width: '10%'
    },
    {
      name: 'Class Name',
      selector: (row) => row.className,
      sortable: true,
      width: '20%'
    },
    {
      name: 'No. of Students',
      selector: (row) => row.numOfStudents,
      sortable: false,
      width: '20%'
    },
    {
      name: 'Instructor',
      selector: (row) => concatInstructors(row.instructors),
      sortable: true,
      width: '25%'
    },
    {
      name: 'Assignment',
      cell: (row) => (
        <div>
          <TableLink
            className="btn btn-link"
            href={`${row.classId}/assignments`}
            onClick={(e) => handleViewAssignmentClick(e, row.classId)}>
            View
          </TableLink>
        </div>
      ),
      width: '10%'
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div key={row.classId}>
          <TableLink
            className="text-warning p-1"
            onClick={() => [selectedRow(row), setOpenUpdateClassModal(true)]}>
            Edit
          </TableLink>
          |
          <TableLink className="text-error p-1" onClick={() => handleDelete(row.classId)}>
            Delete
          </TableLink>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '15%'
    }
  ];

  const handleSearch = (e) => {
    const { value } = e.target;
    const filteredClass = classes.filter((item) =>
      item.className.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredClass(filteredClass);
  };

  useEffect(() => {
    dispatch(clearClassState());
    dispatch(getClass(data));
  }, []);

  useEffect(() => {
    setFilteredClass(classes);
  }, [classes]);

  const handleViewAssignmentClick = (e, id) => {
    e.preventDefault();
    var foundClass = classes.find((item) => item.classId === id);
    if (!foundClass) return;

    const href = e.target.attributes.href.value;
    const breadcrumbObj = {
      label: foundClass.className,
      href: location.pathname + '/' + href
    };
    dispatch(pushBreadcrumb(breadcrumbObj));
    navigate(href);
  };

  const handleDelete = async (id) => {
    const { isConfirmed } = await swalConfirmation('Are you sure you want to delete this class?');
    if (!isConfirmed) return;
    const deleteData = {
      courseId,
      classId: id
    };
    try {
      await classService.deleteClass(deleteData);
      await swalSuccess('Class deleted successfully');
      dispatch(clearCourseState());
      dispatch(getClass(data));
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const selectedRow = (data) => {
    const selectedClass = data;
    setSelectedClass(selectedClass);
  };

  const concatInstructors = (instructors) => {
    console.log(instructors);
    let instructorStr = '';
    for (let index = 0; index < instructors.length; index++) {
      if (index === 0) {
        instructorStr += instructors[index].name;
      } else {
        instructorStr += `, ${instructors[index].name}`;
      }
    }
    return instructorStr;
  };

  return (
    <div>
      <h1>Classes</h1>
      <Breadcrumb />
      <div className="flex mt-4 justify-between">
        <div className="flex w-full space-x-2">
          <Button
            className="btn btn-primary"
            variant="contained"
            href={`${location.pathname}/create`}>
            Create Class
          </Button>
        </div>
        <div>
          <input
            type="text"
            placeholder="search class..."
            className="input input-bordered"
            onChange={handleSearch}
          />
        </div>
      </div>
      <DataTable data={filteredClass} columns={columns} loading={isFetching} />
      <UpdateClassModal
        open={openUpdateClassModal}
        handleClose={() => setOpenUpdateClassModal(false)}
        row={selectedClass}
      />
    </div>
  );
};

export default Classes;
