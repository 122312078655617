import { Slider } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useState } from 'react';

const ScaleResponseReview = ({ data, handleFormChange }) => {
  const { questionId, question, scaleSize, minLabel, maxLabel, scaleAnswer } = data;
  const [currentValue, setCurrentValue] = useState(scaleAnswer ?? Math.ceil(scaleSize / 2));
  const handleChange = (e) => {
    setCurrentValue(e.target.value);
    handleFormChange(e);
  };
  return (
    <Box
      className="h-48 p-4 mt-4 bg-gray-50 text-center flex flex-col justify-center rounded-md drop-shadow-sm"
      sx={{ width: '100%', height: 'auto' }}>
      <span className="font-bold mb-10">{question}</span>
      <div className="flex justify-center space-x-4">
        <span>{minLabel}</span>
        <Slider
          name="scaleAnswer"
          className="w-1/2"
          value={currentValue}
          step={1}
          min={1}
          max={scaleSize}
          valueLabelDisplay="auto"
          marks
          onChange={handleChange}
        />
        <span>{maxLabel}</span>
      </div>
    </Box>
  );
};

export default ScaleResponseReview;
