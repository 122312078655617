import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { clearBreadcrumbs, pushBreadcrumb } from '../../../store/breadcrumb.store';
import { getUserCourses } from '../../../store/courses.store';
import DataTable from '../../../components/DataTable';
import Toast from '../../../components/Toast';
import { formatDatetime } from '../../../utils';
import TableLink from '../../TableLink';
import Breadcrumb from '../../../components/Breadcrumb';
import StudentStats from '../../../components/Stats/StudentStats';

const StudentCourses = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isFetching, courses } = useSelector((state) => state.courses);
  const { user } = useSelector((state) => state.user);
  const [filteredCourses, setFilteredCourses] = useState([]);

  const columns = [
    {
      name: 'Course',
      selector: (row) => row.name,
      sortable: true,
      width: '30%'
    },
    {
      name: 'Start Date',
      selector: (row) => formatDatetime(row.startDate),
      sortable: true,
      width: '25%'
    },
    {
      name: 'End Date',
      selector: (row) => formatDatetime(row.endDate),
      sortable: true,
      width: '25%'
    },
    {
      name: 'Assignments',
      cell: (row) => (
        <TableLink href={`${row.id}/assignments`} onClick={(e) => handleLinkClick(e, row.id)}>
          View Assignments
        </TableLink>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '20%'
    }
  ];

  useEffect(() => {
    dispatch(getUserCourses({ accountId: user.id }));
  }, []);

  useEffect(() => {
    setFilteredCourses(courses);
  }, [courses]);

  useEffect(() => {
    dispatch(clearBreadcrumbs());
    const breadcrumbObj = {
      label: 'Course List',
      href: '/student/courses'
    };
    dispatch(pushBreadcrumb(breadcrumbObj));
  }, []);

  const handleFilter = (e) => {
    const { value } = e.target;
    const filteredCourses = courses.filter((course) =>
      course.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredCourses(filteredCourses);
  };

  const handleLinkClick = (e, courseId) => {
    e.preventDefault();

    var course = courses.find((course) => course.id === courseId);
    if (!course) return;

    const href = e.target.attributes.href.value;
    const breadcrumbObj = {
      label: course.name,
      href: location.pathname + '/' + href
    };
    dispatch(pushBreadcrumb(breadcrumbObj));
    navigate(href);
  };

  return (
    <Fragment>
      <div className="flex justify-center my-4">
        <StudentStats />
      </div>
      <div className="flex justify-between">
        <h1>Course List</h1>
        <input
          type="text"
          placeholder="search course..."
          className="input input-bordered"
          onChange={handleFilter}
        />
      </div>

      {<DataTable data={filteredCourses} columns={columns} loading={isFetching} />}
      <Toast />
    </Fragment>
  );
};

export default StudentCourses;
