import { Button } from '@mui/material';
import React from 'react';

const ReviewType = ({
  setCurrentStep,
  handleFormInputChange,
  form,
  isAnnonymouscheck,
  isSelfReviewcheck
}) => {
  return (
    <div className="space-y-10">
      <div className="grid grid-cols-3 gap-4 items-center">
        {' '}
        <div>Number of Reviewer(s)</div>
        <div className="col-span-2">
          <input
            type="number"
            className="input input-bordered"
            placeholder="2"
            name="numOfReviewer"
            value={form?.numOfReviewer}
            onChange={handleFormInputChange}
          />
        </div>
        <div className="flex flex-col space-y-4">
          <div>
            <input
              type="checkbox"
              className="checkbox checkbox-primary checkbox-sm"
              checked={form?.isAnnonymous}
              onChange={isAnnonymouscheck}
            />
            &nbsp; Is Annonymous
          </div>
          <div className="col-span-2">
            <input
              type="checkbox"
              className="checkbox checkbox-primary checkbox-sm"
              checked={form?.isSelfReview}
              onChange={isSelfReviewcheck}
            />
            &nbsp; Is Self Review
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <Button variant="outlined" onClick={() => setCurrentStep(0)}>
          Back
        </Button>
        <Button className="btn btn-primary" onClick={() => setCurrentStep(2)}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default ReviewType;
