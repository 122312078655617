import { Box } from '@mui/system';
import React from 'react';

const ViewFreeResponseReview = ({ data }) => {
  const { question, answer } = data;
  return (
    <Box
      className="h-auto p-4 mt-4 bg-gray-50 text-center flex flex-col justify-center rounded-md drop-shadow-sm"
      sx={{ width: '100%', height: 'auto' }}>
      <span className="font-bold my-4">{question}</span>
      <span className="break-normal text-left" style={{ whiteSpace: 'pre-line' }}>
        {answer}
      </span>
    </Box>
  );
};

export default ViewFreeResponseReview;
