import http from '../http';

export const createClass = ({ courseId, data }) => http.post(`/courses/${courseId}/classes`, data);

export const getClass = ({ courseId, accountId }) =>
  http.get(`/courses/${courseId}/classes/ClassLists?accountId=${accountId}`, accountId);

export const getClassEdit = ({ courseId, classId }) =>
  http.get(`/courses/${courseId}/classes/${classId}/ClassDetails`);

export const getInstructor = ({ courseId }) => http.get(`/courses/${courseId}/classes/Instructors`);

export const getCourseName = ({ courseId }) => http.get(`/courses/${courseId}/classes/CourseNames`);

export const updateClass = ({ courseId, classId, classes }) =>
  http.put(`/courses/${courseId}/classes/UpdateClass?classId=${classId}`, classes);

export const deleteClass = ({ courseId, classId }) =>
  http.delete(`/courses/${courseId}/classes/${classId}`);

export const getClassStudents = (classId) => http.get(`/class/${classId}/students`);
