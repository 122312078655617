import React, { Fragment, useState } from 'react';
import { Box, Button, Modal, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { swalConfirmation, swalSuccess } from '../../utils';
import FileDropzone from '../FileDropzone';
import { toast } from 'react-toastify';
import InstructorDetailInput from './InstructorDetailInput';
import { userService } from '../../api/services';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getInstructor } from '../../store/instructor.store';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 0.5,
  maxHeight: 0.7,
  bgcolor: 'white',
  boxShadow: 24,
  p: 4
};

const CreateInstructorModal = ({ open, handleClose }) => {
  const initialInstructorState = {
    id: '',
    name: '',
    email: ''
  };
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [newInstructors, setNewInstructors] = useState([initialInstructorState]);

  /**
   * Close modal only when user clicks on the 'x' button.
   *
   * Resets local states before closing.
   *
   * @param {*} _ - unused parameter
   * @param {*} reason - reason for closing modal
   */
  const handleModalClose = async (_, reason) => {
    if (reason !== 'backdropClick') {
      const { isConfirmed } = await swalConfirmation(
        'Closing this modal will discard all changes. Are you sure?'
      );
      if (!isConfirmed) return;

      handleClose();
    }
  };

  const showErrorMsg = () => {
    toast.error('File type not supported');
  };

  const handleAddInstructor = () => {
    setNewInstructors([...newInstructors, initialInstructorState]);
  };

  const handleInstructorInputChange = (e, index) => {
    const { name, value } = e.target;
    const newInstruct = [...newInstructors];
    newInstruct[index][name] = value;
    setNewInstructors(newInstruct);
  };

  const handleDeleteInstructor = (index) => {
    if (newInstructors.length === 1) {
      toast.info('You must have at least one instructor');
      return;
    }
    const newInstruct = [...newInstructors];
    newInstruct.splice(index, 1);
    setNewInstructors(newInstruct);
  };

  const handleSubmit = async () => {
    if (!isValidInput()) {
      toast.error('Please fill all required fields');
      return;
    }

    try {
      await userService.createInstructors(newInstructors);
      await swalSuccess('Instructors created successfully');
      setNewInstructors([initialInstructorState]);
      handleClose();
    } catch (error) {
      console.error(error);
      toast.error('Error creating instructor');
    }
  };

  const isValidInput = () => {
    for (const input of newInstructors) {
      if (!input.id || !input.name || !input.email) return false;
    }
    return true;
  };

  return (
    <Fragment>
      <Modal open={open} disableEscapeKeyDown>
        <Box className="overflow-auto" sx={style}>
          <CloseIcon
            className="absolute right-0 top-0 m-2 cursor-pointer transition ease-in-out delay-130 hover:scale-110 duration-300"
            onClick={handleModalClose}
          />
          <div className="flex flex-col space-y-6 text-center">
            <div>
              <h1>Create Instructor(s)</h1>
              <p className="text-gray-500">Manually enter details to create instructors</p>
            </div>

            {newInstructors.map((instructor, index) => (
              <InstructorDetailInput
                key={index}
                data={instructor}
                handleInputChange={(e) => handleInstructorInputChange(e, index)}
                handleDelete={() => handleDeleteInstructor(index)}
              />
            ))}
            <Button className="btn btn-warning w-1/4" onClick={handleAddInstructor}>
              Add New Instructor
            </Button>
            <Button className="btn btn-primary" onClick={handleSubmit}>
              Save & Create
            </Button>
          </div>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default CreateInstructorModal;
