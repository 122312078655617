import React from 'react';
import CountUp from 'react-countup';

const StatsCard = ({ data }) => {
  const { title, description, stats, icon } = data;
  return (
    <div className="stat place-items-center">
      <div className="stat-figure text-secondary">{icon}</div>
      <div className="stat-title uppercase">{title}</div>
      <CountUp className="stat-value" start={0} end={parseInt(stats)} duration={1.5} />
      <div className="stat-desc">{description}</div>
    </div>
  );
};

export default StatsCard;
