import React, { Fragment, useState } from 'react';
import { Button, Modal } from '@mui/material';
import { Box } from '@mui/system';
import { swalConfirmation, swalSuccess } from '../utils';
import { toast } from 'react-toastify';
import { authService } from '../api/services';

import Toast from './Toast';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 0.5,
  bgcolor: 'white',
  boxShadow: 24,
  p: 4
};

const ChangePasswordModal = ({ open, handleClose }) => {
  const initialFormState = { email: '', oldPassword: '', newPassword: '' };
  const [disableButton, setDisableButton] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [form, setForm] = useState(initialFormState);

  const handleModalClose = async (_, reason) => {
    if (reason !== 'backdropClick') {
      const { isConfirmed } = await swalConfirmation(
        'Closing this modal will discard all changes. Are you sure?'
      );
      if (!isConfirmed) return;
      handleClose();
      resetStates();
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (!isFieldsValid()) {
      toast.error('Please fill in all fields');
      return;
    }
    setDisableButton(true);
    try {
      await authService.changePassword(form);
      await swalSuccess('Password changed successfully');
      resetStates();
      handleClose();
    } catch (error) {
      console.log(error);
      const message = error?.response?.data?.responseException?.exceptionMessage ?? error.message;
      toast.error(message);
    }
  };

  const resetStates = () => {
    setForm(initialFormState);
    setDisableButton(false);
    setShowOldPassword(false);
    setShowNewPassword(false);
  };

  const isFieldsValid = () => {
    const { email, oldPassword, newPassword } = form;
    return email && oldPassword && newPassword;
  };

  return (
    <Fragment>
      <Modal open={open} disableEscapeKeyDown>
        <Box sx={style}>
          <CloseIcon
            className="absolute right-0 top-0 m-2 cursor-pointer transition ease-in-out delay-130 hover:scale-110 duration-300"
            onClick={handleModalClose}
          />
          <form className="flex flex-col space-y-6 text-center" onSubmit={handleChangePassword}>
            <div>
              <h1>Change Password</h1>
            </div>
            <div className="flex flex-col justify-center space-y-4">
              <div className="form-control">
                <label className="label">
                  <span className="label-text font-bold">Email</span>
                </label>
                <input
                  name="email"
                  type="email"
                  className="input input-bordered w-full"
                  placeholder="Enter your email"
                  onChange={handleFormChange}
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text font-bold">Old password</span>
                </label>
                <div className="relative">
                  <input
                    name="oldPassword"
                    type={showOldPassword ? 'text' : 'password'}
                    className="input input-bordered w-full"
                    placeholder="Enter your old password"
                    onChange={handleFormChange}
                  />
                  <div
                    className="absolute top-3 right-3 cursor-pointer"
                    onClick={() => setShowOldPassword(!showOldPassword)}>
                    {showOldPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </div>
                </div>
              </div>
              <div className="form-control">
                <div className="flex">
                  <label className="label">
                    <span className="label-text font-bold">New Password</span>
                  </label>
                  <div
                    className="tooltip tooltip-info mt-1"
                    data-tip="Password must contain atleast one non-alphanumeric character, one number, and one uppercase">
                    <InfoIcon className="text-info" />
                  </div>
                </div>
                <div className="relative">
                  <input
                    name="newPassword"
                    type={showNewPassword ? 'text' : 'password'}
                    className="input input-bordered w-full"
                    placeholder="Enter your new password"
                    onChange={handleFormChange}
                  />
                  <div
                    className="absolute top-3 right-3 cursor-pointer"
                    onClick={() => setShowNewPassword(!showNewPassword)}>
                    {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </div>
                </div>
              </div>
            </div>
            <Button type="submit" className="btn btn-primary" disabled={disableButton}>
              Change Password
            </Button>
          </form>
        </Box>
      </Modal>
      <Toast />
    </Fragment>
  );
};

export default ChangePasswordModal;
