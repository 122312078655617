import React, { useEffect, useState } from 'react';
import StatsCard from '../../components/StatsCard';
import SchoolIcon from '@mui/icons-material/School';
import HomeIcon from '@mui/icons-material/Home';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { getInstructorStats } from '../../api/services/stats.service';

const InstructorStats = () => {
  const initialStats = [
    {
      title: 'Courses',
      description: 'No. of courses',
      stats: 0,
      icon: <HomeIcon />
    },
    {
      title: 'Classes',
      description: 'No. of classes',
      stats: 0,
      icon: <SchoolIcon />
    },
    {
      title: 'Assignments',
      description: 'No. of assignments',
      stats: 0,
      icon: <AssignmentIcon />
    },
    {
      title: 'Submissions',
      description: 'No. of submissions',
      stats: 0,
      icon: <AssignmentTurnedInIcon />
    }
  ];

  const [data, setData] = useState({ loading: true, stats: initialStats });

  const getStats = async () => {
    try {
      const res = await getInstructorStats();
      const { result } = res.data;
      const newStats = [...data.stats];
      newStats[0].stats = result.courses;
      newStats[1].stats = result.classes;
      newStats[2].stats = result.assignments;
      newStats[3].stats = result.submissions;
      setData({ loading: false, stats: newStats });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <div
      className={`stats shadow mt-4 bg-primary text-white ${data.loading ? 'animate-pulse' : ''}`}>
      {data.stats.map((item, index) => (
        <StatsCard key={index} data={item} />
      ))}
    </div>
  );
};

export default InstructorStats;
