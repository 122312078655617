import http from '../http';

export const getAssignmentByClassId = ({ courseId, classId }) =>
  http.get(`/courses/${courseId}/classes/${classId}/assignments`);

export const getAssignmentByCourseId = ({ courseId }) =>
  http.get(`/courses/${courseId}/assignments`);

export const deleteAssignment = ({ courseId, assignmentId }) =>
  http.delete(`/courses/${courseId}/assignments/${assignmentId}`);

export const duplicateAssignment = ({ courseId, assignmentId }) =>
  http.post(`/courses/${courseId}/assignments/${assignmentId}`);

export const updateAssignment = ({ data, courseId, assignmentId }) =>
  http.put(`/courses/${courseId}/assignments/${assignmentId}`, data);

export const createAssignments = ({ data, courseId }) =>
  http.post(`/courses/${courseId}/assignments/create`, data);

export const getrubric = ({ courseId }) => http.get(`/courses/${courseId}/assignments/create`);

export const getAssignmentByCourse = ({ courseId, accountId }) => {
  return http.get(`/courses/${courseId}/assignments?accountId=${accountId}`);
};
