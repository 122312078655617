import React from 'react';

const NavSteps = ({ steps, currentStep, setCurrentStep }) => {
  return (
    <div className="flex justify-center my-20">
      <ul className="steps steps-horizontal">
        {steps.map((step, index) => (
          <li
            key={index}
            className={`w-48 font-semibold step ${index <= currentStep ? 'step-primary' : ''}`}
            onClick={() => setCurrentStep(index)}>
            {step.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NavSteps;
