import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  breadcrumbs: [
    // {
    //   label: 'Course List',
    //   href: '/instructor/courses'
    // }
  ]
};

export const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState,
  reducers: {
    pushBreadcrumb: (state, { payload }) => {
      if (state.breadcrumbs.find((breadcrumb) => breadcrumb.href === payload.href)) return state;
      console.log({ payload });
      state.breadcrumbs.push(payload);
    },
    popBreadcrumb: (state) => {
      state.breadcrumbs.pop();
    },
    clearBreadcrumbs: (state) => {
      state.breadcrumbs = initialState.breadcrumbs;
    },
    spliceBreadcrumbsAfterIndex: (state, { payload }) => {
      state.breadcrumbs = state.breadcrumbs.slice(0, payload + 1);
    }
  }
});

export const { pushBreadcrumb, popBreadcrumb, clearBreadcrumbs, spliceBreadcrumbsAfterIndex } =
  breadcrumbSlice.actions;
export default breadcrumbSlice.reducer;
