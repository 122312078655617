import { Route, Routes } from 'react-router';
import AuthRoute from './components/AuthRoute';
import { Role } from './constants';
import Layout from './components/Layout';

// Pages
import Login from './pages/Login';
import InstructorDashboard from './pages/Instructor/InstructorDashboard';
import StudentDashboard from './pages/Student/StudentDashboard';
import NotFound from './pages/NotFound';
import Unauthorised from './pages/Unauthorised';
import Home from './pages/Home';
import Courses from './pages/Instructor/Courses/Courses';
import CreateCourse from './pages/Instructor/Courses/CreateCourse';
import Classes from './pages/Instructor/Classes/Classes';
import CreateAssignment from './pages/Instructor/Assignments/CreateAssignment';
import Assignment from './pages/Instructor/Assignments/Assignments';
import Submissions from './pages/Instructor/Submissions';
import Reviews from './pages/Instructor/Reviews/Reviews';
import CreateClass from './pages/Instructor/Classes/CreateClass';
import ViewStudentReview from './pages/Instructor/Reviews/ViewStudentReview';
import CourseAssignments from './pages/Instructor/Assignments/CourseAssignments';
import ReviewedByMe from './pages/Student/Reviews/ReviewedByMe';
import ReviewedByOthers from './pages/Student/Reviews/ReviewedByOthers';
import ViewPeerReview from './pages/Student/Reviews/ViewPeerReview';
import StudentCourses from './pages/Student/Courses/StudentCourses';
import MyReview from './pages/Student/Reviews/MyReview';
import StudentAssignments from './pages/Student/Assignments/StudentAssignments';

function App() {
  return (
    <div className="min-h-screen">
      <Routes>
        <Route exact path="login" element={<Login />} />
        <Route element={<AuthRoute />}>
          <Route exact path="/" element={<Home />} />
        </Route>

        {/* Instructor routes */}
        <Route element={<AuthRoute allowedRole={Role.INSTRUCTOR} />}>
          <Route path="instructor" element={<Layout />}>
            <Route path="" element={<NotFound />} />
            <Route path="courses" element={<Courses />} />
            <Route path="courses/create" element={<CreateCourse />} />
            <Route path="courses/:courseId/classes" element={<Classes />} />
            <Route path="courses/:courseId/assignments" element={<CourseAssignments />} />
            <Route path="courses/:courseId/assignments/create" element={<CreateAssignment />} />
            <Route path="courses/:courseId/classes/create" element={<CreateClass />} />
            <Route path="courses/:courseId/classes/:classId/assignments" element={<Assignment />} />
            <Route
              path="courses/:courseId/classes/:classId/assignments/:assignmentId/submissions"
              element={<Submissions />}
            />
            <Route
              path="courses/:courseId/classes/:classId/assignments/:assignmentId/submissions/:submissionId/reviews"
              element={<Reviews />}
            />
            <Route
              path="courses/:courseId/classes/:classId/assignments/:assignmentId/submissions/:submissionId/reviews/:reviewId/feedback"
              element={<ViewStudentReview />}
            />
          </Route>
        </Route>

        {/* Student routes */}
        <Route element={<AuthRoute allowedRole={Role.STUDENT} />}>
          <Route path="student" element={<Layout />}>
            <Route path="courses" element={<StudentCourses />} />
            <Route path="courses/:courseId/assignments" element={<StudentAssignments />} />
            <Route
              path="courses/:courseId/assignments/:assignmentId/reviews/me"
              element={<ReviewedByMe />}
            />
            <Route
              path="courses/:courseId/assignments/:assignmentId/reviews/:reviewId/feedback/me"
              element={<MyReview />}
            />
            <Route
              path="courses/:courseId/assignments/:assignmentId/reviews/:reviewId/feedback/me/view"
              element={<ViewPeerReview />}
            />
            <Route
              path="courses/:courseId/assignments/:assignmentId/reviews/peers"
              element={<ReviewedByOthers />}
            />
            <Route
              path="courses/:courseId/assignments/:assignmentId/reviews/:reviewId/peers"
              element={<ViewPeerReview />}
            />
          </Route>
        </Route>

        <Route path="unauthorized" element={<Unauthorised />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
