import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearAuthState, login } from '../store/auth.store';
import { useNavigate } from 'react-router-dom';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import { swalConfirmation, swalError } from '../utils/sweet-alert.utils';
import FloatingSquares from '../components/FloatingSquares';
import { Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import ChangePasswordModal from '../components/ChangePasswordModal';
import { authService } from '../api/services';
import { toast } from 'react-toastify';

const Login = () => {
  const [openChangePwModal, setOpenChangePwModal] = useState(false);
  const [showLoadingButton, setShowLoadingButton] = useState(false);
  const { isAuthenticated, isSuccess, isError } = useSelector((state) => state.auth);
  const { user, isSuccess: userSuccess } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form, setForm] = useState({
    email: '',
    password: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowLoadingButton(true);
    const res = await authService.requireChangePassword(form);
    const requireChangePassword = res.data.result;
    if (requireChangePassword) {
      form.password = '';
      const { isConfirmed } = await swalConfirmation(
        'You need to change your password before you can login',
        'Password Change Required'
      );
      if (isConfirmed) setOpenChangePwModal(true);
      setShowLoadingButton(false);
      return;
    }
    dispatch(login(form));
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    return () => {
      dispatch(clearAuthState());
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      if (userSuccess) {
        const url = user.roles.length > 1 ? '/' : `/${user.roles[0].toLowerCase()}/courses`;
        dispatch(clearAuthState());
        navigate(url, { replace: true });
      }
    }
    if (isError) {
      toast.error('Invalid Credentials');
      dispatch(clearAuthState());
      setShowLoadingButton(false);
    }
  }, [isSuccess, userSuccess, isError]);

  return (
    <div className="w-screen h-screen">
      <FloatingSquares />
      <div className="min-h-full h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div className="flex justify-center">
            <h2 className="mt-6 text-center text-3xl font-bold text-gray-800">Login to </h2>
            <img
              className="h-10 w-auto ml-1 mt-5"
              src={require('../assets/images/logo.png')}
              alt="Workflow"
            />
          </div>
          <form className="mt-8 space-y-2" onSubmit={handleSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  onChange={handleChange}
                  value={form.email}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  onChange={handleChange}
                  value={form.password}
                />
              </div>
            </div>
            <div className="text-right">
              <Button className="normal-case" onClick={() => setOpenChangePwModal(true)}>
                Forgot password
              </Button>
            </div>
            <div>
              <LoadingButton
                id="sign-in"
                type="submit"
                className="btn btn-primary w-full group relative flex justify-center"
                loading={showLoadingButton}
                loadingPosition="end">
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockRoundedIcon
                    className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                    aria-hidden="true"
                  />
                </span>
                Sign in
              </LoadingButton>
            </div>
          </form>
        </div>
      </div>
      <ChangePasswordModal
        open={openChangePwModal}
        handleClose={() => setOpenChangePwModal(false)}
      />
    </div>
  );
};

export default Login;
