import Avatar from 'react-avatar';
import React from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../store/auth.store';
import { Role } from '../constants';
import { useNavigate } from 'react-router';

const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isSuccess } = useSelector((state) => state.user);

  const goDashboard = () => {
    const { roles } = user;

    if (roles.length > 1) {
      navigate('/');
    } else if (roles.includes(Role.INSTRUCTOR)) {
      navigate('/instructor/courses');
    } else if (roles.includes(Role.STUDENT)) {
      navigate('/student/courses');
    }
  };

  return (
    <div className="h-16 border-b-2">
      <div className="flex justify-between">
        <img
          className="ml-4 mt-2 h-10 w-auto hover:cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300"
          src={require('../assets/images/logo.png')}
          onClick={goDashboard}
        />
        <div className="flex justify-center mr-4">
          <p className="mt-4 text-l font-semibold">Hello, {isSuccess && user.name}</p>
          <Avatar
            className="mt-1 mx-1"
            name={isSuccess ? user.name : 'User'}
            size="40"
            maxInitials={2}
            round
          />
          <IconButton id="logout" className="mt-2 ml-2" onClick={() => dispatch(logout())}>
            <LogoutIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
