import React, { Fragment, useState, useEffect } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import NavSteps from '../../../components/NavSteps';
import { useDispatch, useSelector } from 'react-redux';
import { swalConfirmation, swalSuccess } from '../../../utils';
import { toast } from 'react-toastify';
import Toast from '../../../components/Toast';
import {
  createAssignment,
  clearAssignmentState,
  getAssignmentByCourseId
} from '../../../store/assignment.store';
import { useLocation, useNavigate, useParams } from 'react-router';

import CreateRubric from '../../../components/Assignment/CreateRubric';
import AssignmentDetails from '../../../components/Assignment/AssignmentDetails';
import ReviewType from '../../../components/Assignment/ReviewType';
import { RecordVoiceOver } from '@mui/icons-material';

const CreateAssignment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { isSuccess, isError, errorMessage } = useSelector((state) => state.assignment);
  const { user } = useSelector((state) => state.user);
  const [disableButton, setDisableButton] = useState(false);
  const [createAssignmentsPage, setCreateAssignmentsPage] = useState(false);
  const [questions, setQuestions] = useState([]);
  const { courseId } = params;
  const data = {
    courseId
  };
  const [form, setForm] = useState({
    name: '',
    instruction: '',
    isAnnonymous: false,
    isSelfReview: false,
    numOfReviewer: '',
    reviewStartDate: '',
    reviewEndDate: '',
    createdById: user.id,
    courseId: courseId,
    rubricName: '',
    IsLibrary: false,
    questions: [],
    rubricId: 1
  });
  const [currentStep, setCurrentStep] = useState(0);
  const steps = [
    {
      title: 'Assignment Details',
      icon: <HomeIcon />
    },
    {
      title: 'Review Type',
      icon: <PersonIcon />
    },
    {
      title: 'Rubric',
      icon: <RecordVoiceOver />
    }
  ];

  /**
   * Handle form input change.
   *
   * Set local state with new value.
   */
  const handleFormInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const isSelfReviewcheck = (e) => {
    setForm({ ...form, isSelfReview: !form.isSelfReview });
  };

  const isAnnonymouscheck = (e) => {
    setForm({ ...form, isAnnonymous: !form.isAnnonymous });
  };

  const isLibrarycheck = (e) => {
    setForm({ ...form, IsLibrary: !form.IsLibrary });
  };

  useEffect(() => {
    console.log({ form });
  }, [form]);

  /**
   * Handle course creation.
   *
   * 1. Validate form inputs.
   * 2. Show confirmation alert.
   * 3. Append data to form data.
   * 4. Send form data to server.
   */
  const handleCreate = async () => {
    setCreateAssignmentsPage(true);
    if (!validateForm()) return;
    const { isConfirmed } = await swalConfirmation(
      'Are you sure you want to create this assignment?'
    );
    if (!isConfirmed) return;
    setDisableButton(true);
    dispatch(clearAssignmentState());
    console.log(form);
    dispatch(createAssignment({ courseId: data.courseId, data: form }));
  };

  /**
   * Validate form data.
   *
   * @returns {boolean} true if form is valid, false otherwise
   */
  const validateForm = () => {
    if (!form.name || !form.reviewStartDate || !form.reviewEndDate) {
      toast.error('Please fill in all fields.');
      return false;
    }
    if (form.IsLibrary && !form.rubricName) {
      toast.error('Please enter rubric name');
      return false;
    }
    return true;
  };

  /**
   * Handle isSuccess or isError state change.
   *
   * isSuccess:
   *  1. Show success alert.
   *  2. Dispatch action to clear course state.
   *  3. Dispatch action to get courses.
   *  4. Close modal.
   *
   * isError:
   *  1. Show error toast.
   *  2. Dispatch action to clear course state.
   *  3. Enable button.
   */
  useEffect(() => {
    if (!createAssignmentsPage) return;
    if (isSuccess) {
      swalSuccess('Assignment created successfully').then(() => {
        resetStates();
        dispatch(clearAssignmentState());
        dispatch(getAssignmentByCourseId(data));
        navigate(-1, { replace: true });
      });
    } else if (isError) {
      toast.error(errorMessage ?? 'Error creating assignment');
      dispatch(clearAssignmentState());
      setDisableButton(false);
      setCreateAssignmentsPage(false);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    setForm({ ...form, questions });
  }, [questions]);

  /**
   * Reset local states to default.
   */
  const resetStates = () => {
    setDisableButton(false);
  };

  const handleSubmit = () => {
    console.log({ form });
  };

  const showComponent = () => {
    switch (currentStep) {
      case 0:
        return (
          <AssignmentDetails
            setCurrentStep={setCurrentStep}
            handleFormInputChange={handleFormInputChange}
            form={form}
          />
        );
      case 1:
        return (
          <ReviewType
            setCurrentStep={setCurrentStep}
            handleFormInputChange={handleFormInputChange}
            form={form}
            isSelfReviewcheck={isSelfReviewcheck}
            isAnnonymouscheck={isAnnonymouscheck}
          />
        );
      case 2:
        return (
          <CreateRubric
            setQuestions={setQuestions}
            form={form}
            isLibrarycheck={isLibrarycheck}
            handleFormInputChange={handleFormInputChange}
            handleSubmit={handleSubmit}
            setCurrentStep={setCurrentStep}
            handleCreate={handleCreate}
          />
        );
      default:
        break;
    }
  };

  return (
    <Fragment>
      <NavSteps steps={steps} currentStep={currentStep} setCurrentStep={setCurrentStep} />
      <div className="mx-auto w-1/2 mt-5 pb-5">{showComponent()}</div>
      <Toast />
    </Fragment>
  );
};

export default CreateAssignment;
