import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { pushBreadcrumb } from '../../../store/breadcrumb.store';
import { getSubmissionReviews } from '../../../store/review.store';
import DataTable from '../../../components/DataTable';
import Toast from '../../../components/Toast';
import Breadcrumb from '../../../components/Breadcrumb';
import { formatDatetime } from '../../../utils';
import TableLink from '../../TableLink';

const Reviews = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const { courseId, classId, assignmentId, submissionId } = params;
  const data = {
    courseId,
    classId,
    assignmentId,
    submissionId
  };
  const { isFetching, reviews } = useSelector((state) => state.review);
  const [filteredReviews, setFilteredReviews] = useState([]);

  const columns = [
    {
      name: 'Student ID',
      selector: (row) => row.studentId,
      sortable: true,
      width: '20%'
    },
    {
      name: 'Student Name',
      selector: (row) => row.name,
      sortable: true,
      width: '20%'
    },
    {
      name: 'Reviewed Date',
      selector: (row) => (!row.reviewedAt ? '-' : formatDatetime(row.reviewedAt)),
      sortable: true,
      width: '20%'
    },
    {
      name: 'Review Status',
      selector: (row) => (!row.reviewedAt ? 'Not Completed' : 'Completed'),
      sortable: true,
      width: '20%'
    },
    {
      name: 'Actions',
      cell: (row) => (
        <TableLink
          href={`${row.id}/feedback`}
          onClick={(e) => handleLinkClick(e, row.id)}
          disabled={row.reviewedAt === null}>
          View Feedback
        </TableLink>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '20%'
    }
  ];

  useEffect(() => {
    dispatch(getSubmissionReviews(data));
  }, []);

  useEffect(() => {
    setFilteredReviews(reviews);
  }, [reviews]);

  const handleFilter = (e) => {
    const { value } = e.target;
    const filteredReviews = reviews.filter(
      (review) =>
        review.studentId.toLowerCase().includes(value.toLowerCase()) ||
        review.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredReviews(filteredReviews);
  };

  const handleLinkClick = (e, reviewId) => {
    e.preventDefault();

    var review = reviews.find((review) => review.id === reviewId);
    if (!review) return;

    const href = e.target.attributes.href.value;
    const breadcrumbObj = {
      label: review.name,
      href: location.pathname + '/' + href
    };
    dispatch(pushBreadcrumb(breadcrumbObj));
    navigate(href);
  };

  return (
    <div>
      <h1>Reviews</h1>
      <Breadcrumb />
      <div className="flex space-x-2 justify-end">
        <input
          type="text"
          placeholder="search reviews..."
          className="input input-bordered"
          onChange={handleFilter}
        />
      </div>
      {<DataTable data={filteredReviews} columns={columns} loading={isFetching} />}
      <Toast />
    </div>
  );
};

export default Reviews;
