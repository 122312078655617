import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { assignmentService } from '../api/services';

export const getAssignmentByCourseId = createAsyncThunk(
  'assignments/get',
  async (data, { rejectWithValue }) => {
    try {
      const response = await assignmentService.getAssignmentByCourseId(data);
      const assignments = response.data.result;
      return assignments;
    } catch (error) {
      return rejectWithValue(error.responseException.exceptionMessage);
    }
  }
);

export const getAssignmentByClassId = createAsyncThunk(
  'assignments/get',
  async (data, { rejectWithValue }) => {
    try {
      const response = await assignmentService.getAssignmentByClassId(data);
      const assignments = response.data.result;
      return assignments;
    } catch (error) {
      return rejectWithValue(error.responseException.exceptionMessage);
    }
  }
);

export const deleteAssignment = createAsyncThunk(
  'assignment/delete',
  async (data, { rejectWithValue }) => {
    try {
      const response = await assignmentService.deleteAssignment(data);
      const assignment = response.data.result;
      return assignment;
    } catch (error) {
      return rejectWithValue(error.responseException.exceptionMessage);
    }
  }
);

export const duplicateAssignment = createAsyncThunk(
  'assignment/duplicate',
  async (data, { rejectWithValue }) => {
    try {
      const response = await assignmentService.duplicateAssignment(data);
      const assignment = response.data.result;
      return assignment;
    } catch (error) {
      return rejectWithValue(error.responseException.exceptionMessage);
    }
  }
);

export const updateAssignment = createAsyncThunk(
  'assignment/update',
  async (data, { rejectWithValue }) => {
    try {
      const response = await assignmentService.updateAssignment(data, data.assignmentId);
      const assignment = response.data.result;
      return assignment;
    } catch (error) {
      return rejectWithValue(error.responseException.exceptionMessage);
    }
  }
);

export const createAssignment = createAsyncThunk(
  'assignment/create',
  async (data, { rejectWithValue }) => {
    try {
      const response = await assignmentService.createAssignments(data);
      const assignment = response.data.result;
      return assignment;
    } catch (error) {
      return rejectWithValue(error.responseException.exceptionMessage);
    }
  }
);

export const getAssignmentByCourse = createAsyncThunk(
  'assignment/getAssignmentByMe',
  async (data, { rejectWithValue }) => {
    try {
      const response = await assignmentService.getAssignmentByCourse(data);
      const assignment = response.data.result;
      return assignment;
    } catch (error) {
      return rejectWithValue(error.responseException.exceptionMessage);
    }
  }
);

const initialState = {
  assignments: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: ''
};

export const assignmentSlice = createSlice({
  name: 'assignment',
  initialState,
  reducers: {
    clearAssignmentState: (state) => {
      state.isSuccess = false;
      state.isFetching = false;
      state.isError = false;
    }
  },
  extraReducers: {
    [getAssignmentByClassId.fulfilled]: (state, action) => {
      state.assignments = action.payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getAssignmentByClassId.pending]: (state) => {
      state.isFetching = true;
    },
    [getAssignmentByClassId.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [getAssignmentByCourse.fulfilled]: (state, action) => {
      state.assignments = action.payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getAssignmentByCourse.pending]: (state) => {
      state.isFetching = true;
    },
    [getAssignmentByCourse.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [createAssignment.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [createAssignment.pending]: (state) => {
      state.isFetching = true;
    },
    [createAssignment.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [updateAssignment.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [updateAssignment.pending]: (state) => {
      state.isFetching = true;
    },
    [updateAssignment.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [deleteAssignment.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [deleteAssignment.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteAssignment.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [duplicateAssignment.fulfilled]: (state, action) => {
      state.assignments = action.payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [duplicateAssignment.pending]: (state) => {
      state.isFetching = true;
    },
    [duplicateAssignment.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    }
  }
});

export const { clearAssignmentState } = assignmentSlice.actions;
export default assignmentSlice.reducer;
