import { Button } from '@mui/material';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/NavBar';

const Home = () => {
  return (
    <Fragment>
      <NavBar />
      <div className="flex flex-col justify-center items-center mt-8 space-y-10">
        <div className="text-center">
          <h1>Welcome to Peer Review</h1>
          <h2>Please select your desired view.</h2>
        </div>
        <div className="flex justify-center space-x-2 mt-2">
          <Link className="btn btn-primary" to="/instructor">
            Instructor View
          </Link>
          <Link className="btn btn-primary" to="/student">
            Student View
          </Link>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
